var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticStyle: { margin: "5px 0" } },
    [
      _c("sync-with-intercom", { attrs: { uid: _vm.uid } }),
      _c("sync-with-stripe", { attrs: { uid: _vm.uid } })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }