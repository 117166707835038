<template>
  <div style="margin: 5px 0">
    <sync-with-intercom :uid="uid" />
    <sync-with-stripe :uid="uid" />
  </div>
</template>

<script>
import SyncWithIntercom from '@/components/syncers/SyncWithIntercom';
import SyncWithStripe from '@/components/syncers/SyncWithStripe';

export default {
  name: 'Syncers',
  components: {
    SyncWithStripe,
    SyncWithIntercom,
  },
  props: {
    uid: {
      type: String,
      default: '',
    },
  },
};
</script>

<style scoped>

</style>
