var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    [
      _c(
        "v-toolbar",
        { attrs: { dark: "", color: "primary" } },
        [
          _c("v-toolbar-title", [
            _vm._v(
              "\n      Endre saldoen til " +
                _vm._s(_vm.customer.profile.firstName) +
                " " +
                _vm._s(_vm.customer.profile.lastName) +
                "\n    "
            )
          ]),
          _c("v-spacer"),
          _c(
            "v-toolbar-items",
            [
              _c(
                "v-btn",
                {
                  attrs: { dark: "", text: "" },
                  on: {
                    click: function($event) {
                      return _vm.$emit("close")
                    }
                  }
                },
                [_vm._v("\n        Lukk\n      ")]
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-container",
        [
          _c("v-row", [
            _c("h1", [
              _vm._v("Saldo: " + _vm._s(_vm.balance.balance) + " minutter")
            ])
          ]),
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { attrs: { cols: "6" } },
                [
                  _c("v-row", [_c("h2", [_vm._v("Endre saldo")])]),
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "4" } },
                        [
                          _c("v-text-field", {
                            attrs: {
                              prefix:
                                _vm.minutes >= 0 ? "Legg til" : "Trekk fra",
                              suffix: "minutter",
                              type: "number"
                            },
                            model: {
                              value: _vm.minutes,
                              callback: function($$v) {
                                _vm.minutes = $$v
                              },
                              expression: "minutes"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "6" } },
                        [
                          _c("v-text-field", {
                            attrs: { label: "Beskrivelse", type: "text" },
                            model: {
                              value: _vm.description,
                              callback: function($$v) {
                                _vm.description = $$v
                              },
                              expression: "description"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "2" } },
                        [
                          _c(
                            "v-btn",
                            {
                              attrs: {
                                color: "primary",
                                disabled: !_vm.description || _vm.minutes === 0,
                                loading: _vm.loading
                              },
                              on: { click: _vm.submit }
                            },
                            [_vm._v("\n              Send\n            ")]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: "6" } },
                [
                  _c("h2", [_vm._v("Tidligere transaksjoner")]),
                  _c("v-data-table", {
                    attrs: {
                      headers: _vm.headers,
                      items: _vm.balance.transactions,
                      loading: _vm.loading,
                      "no-data-text": "Ingen",
                      "sort-by": "created",
                      "sort-desc": ""
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "item.created",
                        fn: function(ref) {
                          var item = ref.item
                          return [
                            _vm._v(
                              "\n            " +
                                _vm._s(
                                  _vm.$moment.unix(item.created).format("LLLL")
                                ) +
                                "\n          "
                            )
                          ]
                        }
                      }
                    ])
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }