var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      !_vm.resetDialog
        ? _c(
            "el-row",
            { staticClass: "login-box" },
            [
              _c(
                "el-row",
                {
                  staticStyle: { "text-align": "center" },
                  attrs: { type: "flex", justify: "space-around" }
                },
                [
                  _c("img", {
                    staticStyle: { margin: "auto" },
                    attrs: {
                      src: require("../../public/learnlink-logo.png"),
                      alt: "Learnlink logo"
                    }
                  })
                ]
              ),
              _c(
                "el-form",
                [
                  _c(
                    "el-row",
                    {
                      staticStyle: {
                        "text-align": "center",
                        "margin-bottom": "20px"
                      },
                      attrs: { type: "flex", justify: "center" }
                    },
                    [
                      _c("h3", { staticStyle: { margin: "0" } }, [
                        _vm._v("\n          Logg inn\n        ")
                      ])
                    ]
                  ),
                  _c(
                    "el-row",
                    {
                      staticStyle: {
                        "text-align": "center",
                        "margin-bottom": "40px"
                      },
                      attrs: { type: "flex", justify: "center", gutter: 5 }
                    },
                    [
                      _c(
                        "el-col",
                        { attrs: { xs: 14, sm: 14, md: 12, lg: 12 } },
                        [
                          _c("el-input", {
                            attrs: { type: "email", placeholder: "E-post" },
                            nativeOn: {
                              keyup: function($event) {
                                if (
                                  !$event.type.indexOf("key") &&
                                  _vm._k(
                                    $event.keyCode,
                                    "enter",
                                    13,
                                    $event.key,
                                    "Enter"
                                  )
                                ) {
                                  return null
                                }
                                return _vm.signInUsingEmail($event)
                              }
                            },
                            model: {
                              value: _vm.login.email,
                              callback: function($$v) {
                                _vm.$set(_vm.login, "email", $$v)
                              },
                              expression: "login.email"
                            }
                          }),
                          _c("el-input", {
                            attrs: { type: "password", placeholder: "Passord" },
                            nativeOn: {
                              keyup: function($event) {
                                if (
                                  !$event.type.indexOf("key") &&
                                  _vm._k(
                                    $event.keyCode,
                                    "enter",
                                    13,
                                    $event.key,
                                    "Enter"
                                  )
                                ) {
                                  return null
                                }
                                return _vm.signInUsingEmail($event)
                              }
                            },
                            model: {
                              value: _vm.login.password,
                              callback: function($$v) {
                                _vm.$set(_vm.login, "password", $$v)
                              },
                              expression: "login.password"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { xs: 4, sm: 3, md: 2, lg: 2 } },
                        [
                          _c(
                            "el-button",
                            {
                              staticStyle: { height: "82px" },
                              attrs: { type: "primary" },
                              on: { click: _vm.signInUsingEmail }
                            },
                            [_vm._v("\n            Logg inn\n          ")]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }