var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-app",
    { attrs: { id: "app" } },
    [
      _vm.$store.state.account.uid
        ? _c(
            "v-navigation-drawer",
            {
              attrs: {
                color: "white",
                width: "170px",
                app: "",
                fixed: "",
                permanent: "",
                "expand-on-hover": "",
                stateless: ""
              }
            },
            [
              _c("div", { staticStyle: { margin: "5px" } }, [
                _c("img", {
                  attrs: { src: "/blue-icon-01-small.png", width: "45px" }
                })
              ]),
              _c("v-divider"),
              _c(
                "v-list",
                { attrs: { shaped: "" } },
                [
                  _c(
                    "v-list-item-group",
                    {
                      model: {
                        value: _vm.menuItemActive,
                        callback: function($$v) {
                          _vm.menuItemActive = $$v
                        },
                        expression: "menuItemActive"
                      }
                    },
                    [
                      _c(
                        "v-list-item",
                        {
                          on: {
                            click: function($event) {
                              return _vm.$router.push("/")
                            }
                          }
                        },
                        [
                          _c(
                            "v-list-item-icon",
                            [
                              _c("v-icon", { attrs: { color: "#009bff" } }, [
                                _vm._v("\n              list\n            ")
                              ])
                            ],
                            1
                          ),
                          _c(
                            "v-list-item-content",
                            [
                              _c("v-list-item-title", [
                                _vm._v("\n              Oppdrag\n            ")
                              ])
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-list-item",
                        {
                          on: {
                            click: function($event) {
                              return _vm.$router.push("/customers")
                            }
                          }
                        },
                        [
                          _c(
                            "v-list-item-icon",
                            [
                              _c("v-icon", { attrs: { color: "#009bff" } }, [
                                _vm._v(
                                  "\n              supervisor_account\n            "
                                )
                              ])
                            ],
                            1
                          ),
                          _c(
                            "v-list-item-content",
                            [
                              _c("v-list-item-title", [
                                _vm._v("\n              Kunder\n            ")
                              ])
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-list-item",
                        {
                          on: {
                            click: function($event) {
                              return _vm.$router.push("/sellers")
                            }
                          }
                        },
                        [
                          _c(
                            "v-list-item-icon",
                            [
                              _c("v-icon", { attrs: { color: "#009bff" } }, [
                                _vm._v("\n              people\n            ")
                              ])
                            ],
                            1
                          ),
                          _c(
                            "v-list-item-content",
                            [
                              _c("v-list-item-title", [
                                _vm._v("\n              Lærere\n            ")
                              ])
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-list-item",
                        {
                          on: {
                            click: function($event) {
                              return _vm.$router.push("/lessons")
                            }
                          }
                        },
                        [
                          _c(
                            "v-list-item-icon",
                            [
                              _c("v-icon", { attrs: { color: "#009bff" } }, [
                                _vm._v(
                                  "\n              query_builder\n            "
                                )
                              ])
                            ],
                            1
                          ),
                          _c(
                            "v-list-item-content",
                            [
                              _c("v-list-item-title", [
                                _vm._v("\n              Timer\n            ")
                              ])
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-list-item",
                        {
                          on: {
                            click: function($event) {
                              return _vm.signOut()
                            }
                          }
                        },
                        [
                          _c(
                            "v-list-item-icon",
                            [
                              _c("v-icon", { attrs: { color: "#009bff" } }, [
                                _vm._v("\n              close\n            ")
                              ])
                            ],
                            1
                          ),
                          _c(
                            "v-list-item-content",
                            [
                              _c("v-list-item-title", [
                                _vm._v("\n              Logg ut\n            ")
                              ])
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _c("v-content", [_c("router-view")], 1),
      _c(
        "v-snackbar",
        {
          attrs: {
            color: _vm.$store.state.ui.alert.color,
            timeout: 8000,
            "multi-line": ""
          },
          model: {
            value: _vm.$store.state.ui.alert.active,
            callback: function($$v) {
              _vm.$set(_vm.$store.state.ui.alert, "active", $$v)
            },
            expression: "$store.state.ui.alert.active"
          }
        },
        [
          _c("span", { staticStyle: { margin: "auto", "font-size": "16px" } }, [
            _vm._v(
              "\n      " + _vm._s(_vm.$store.state.ui.alert.message) + "\n    "
            )
          ]),
          _c(
            "v-btn",
            { attrs: { color: "white", dark: "", text: "" } },
            [_c("v-icon", [_vm._v("mdi-close")])],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }