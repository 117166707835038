<template>
  <v-container fluid>
    <v-card>
      <v-toolbar flat dark color="#009bff">
        <v-text-field
          v-model="searchQuery"
          append-icon="search"
          label="Søk etter fornavn, etternavn, epost eller UID (kun ett søkeord)"
          single-line
          hide-details
          :disabled="path !== undefined"
          :placeholder="path ? path : ''"
          @keydown.enter="search"
        />
        <v-spacer />
        <v-btn
          :loading="loading"
          outlined
          @click="search"
        >
          Søk
        </v-btn>
      </v-toolbar>

      <v-data-table
        :headers="headers"
        :items="lessons"
        :loading="loading"
        loading-text="Laster inn timer..."
        no-data-text="Ingen timer funnet"
      >
        <template v-slot:item.ID="{ item }">
          <p class="helptext">
            {{ item.ID }}
          </p>
          <v-btn
            v-if="!item.cancelled && (!item.subtracted && !item.transferred && (!item.paymentID || item.paymentID === 'N/A'))"
            color="red"
            dark
            small
            :loading="item.loading"
            @click="updateCancellation(item, 'cancel')"
          >
            Kanseller time
          </v-btn>
          <v-btn
            v-else-if="item.cancelled"
            color="orange"
            dark
            small
            :loading="item.loading"
            @click="updateCancellation(item, 'uncancel')"
          >
            Angre kansellering
          </v-btn>
          <v-btn
            color="primary"
            small
            dark
            @click="openInFirestore('lessons/' + item.ID)"
          >
            Åpne i Firestore
          </v-btn>
        </template>

        <template v-slot:item.customerUID="{ item }">
          <span>
            <p v-if="item.customer && item.customer.profile">
              {{ item.customer.profile.firstName }}
              {{ item.customer.profile.lastName }}
            </p>
            <p style="font-size: 10px">
              {{ item.customerUID }}
            </p>
            <p v-if="item.customer && item.customer.account" style="font-size: 8px">
              {{ item.customer.account.phone }}
            </p>
          </span>
          <v-btn color="#009BFF" dark small @click="simulate(item.customerUID)">
            Simulér
          </v-btn>
        </template>

        <template v-slot:item.sellerUID="{ item }">
          <span v-if="item.seller">
            <p>{{ item.seller.profile.firstName }} {{ item.seller.profile.lastName }}</p>
            <p style="font-size: 10px">
              {{ item.sellerUID }}
            </p>
            <p v-if="item.seller.account.phone" style="font-size: 8px">
              {{ item.seller.account.phone }}
            </p>
          </span>
          <v-btn
            color="#009BFF"
            dark
            small
            :disabled="!item.sellerUID"
            @click="simulate(item.sellerUID)"
          >
            Simulér
          </v-btn>
        </template>

        <template v-slot:item.startTime="{ item }">
          {{ $moment.unix(item.startTime).format('LLLL') }}
        </template>

        <template v-slot:item.endTime="{ item }">
          {{ $moment.unix(item.endTime).format('LLLL') }}
        </template>

        <template v-slot:item.duration="{ item }">
          <strong>{{ Math.round(item.duration / 60) }} timer</strong><br>
          {{ item.duration }} minutter
        </template>

        <template v-slot:item.report.created="{ item }">
          <span v-if="!item.reportID" style="color: red"><strong>Mangler rapport!</strong></span>
          <span v-else-if="item.report.created">
            {{ $moment.unix(item.report.created).format('L') }}<br>
            ({{ $moment.unix(item.report.created).diff($moment.unix(item.endTime), 'days') }} dager for sent)
          </span>
        </template>

        <template v-slot:item.location="{ item }">
          <strong>{{ item.location }}</strong><br><br>
          Duration: <strong>{{ item.duration }}</strong><br>
          Travel Duration: <strong>{{ item.travelDuration }}</strong>
          <v-btn
            v-if="item.location === 'home' && (item.paymentID === 'N/A' || item.paymentID === '') && !item.subtracted"
            dark
            small
            color="primary"
            :loading="item.loading"
            @click="updateTravelDuration(item)"
          >
            Oppdater reisetid
          </v-btn>
        </template>

        <template v-slot:item.subtracted="{ item }">
          <p><strong>{{ item.duration + (item.travelDuration || 0) }} minutter</strong></p>
          <span v-if="item.subtracted > 0">{{ $moment.unix(item.subtracted).format('L') }}</span>
          <span v-else-if="item.subtracted === -1">Trukket manuelt</span>
          <span v-else>Ikke trukket</span>
          <br>
          <v-btn
            v-if="item.duration && item.subtracted === 0 && (item.paymentID === 'N/A' || item.paymentID === '') && (item.travelDuration > 0 || item.location !== 'home') && item.startTime < $moment().unix()"
            color="orange"
            dark
            small
            :loading="item.loading"
            @click="subtract(item)"
          >
            Trekk kunde nå
          </v-btn>
        </template>

        <template v-slot:item.transferred="{ item }">
          <p><strong>{{ Math.round(item.amount / 100) || 'MANGLER' }} kr.</strong></p>
          <span v-if="item.transferred > 0">{{ $moment.unix(item.transferred).format('L') }}</span>
          <span v-else-if="item.transferred === -1">Overført manuelt</span>
          <span v-else>Ikke overført</span>
          <br>
          <v-btn
            v-if="item.amount && item.transferred === 0 && (item.paymentID === 'N/A' || item.paymentID === '') && item.startTime < $moment().unix()"
            color="orange"
            dark
            small
            :loading="item.loading"
            @click="transfer(item)"
          >
            Overfør
          </v-btn>
          <v-btn
            v-if="item.subtracted !== 0 && item.transferred === 0 && (item.paymentID === 'N/A' || item.paymentID === '')"
            dark
            small
            color="primary"
            :loading="item.loading"
            @click="updateAmount(item)"
          >
            Oppdater sum
          </v-btn>
          <v-btn
            v-if="item.subtracted !== 0 && item.transferred === 0 && (item.paymentID === 'N/A' || item.paymentID === '') && item.startTime < $moment().unix()"
            color="orange"
            dark
            small
            :loading="item.loading"
            @click="update(item, 'transferred', -1)"
          >
            Overført manuelt
          </v-btn>
        </template>
      </v-data-table>
    </v-card>
  </v-container>
</template>

<script>
import { allCategories } from '../../categories';

export default {
  props: ['path'],
  data() {
    return {
      allCategories,
      searchQuery: '',
      dialogVisible: false,
      headers: [
        {
          text: 'lessonID',
          align: 'center',
          sortable: true,
          value: 'ID',
          width: '100px',
        },
        {
          text: 'projectID',
          align: 'center',
          sortable: true,
          value: 'projectID',
          width: '100px',
        },
        {
          text: 'Customer',
          align: 'center',
          sortable: true,
          value: 'customerUID',
          width: '100px',
        },
        {
          text: 'Seller',
          align: 'center',
          sortable: true,
          value: 'sellerUID',
          width: '100px',
        },
        {
          text: 'Start Time',
          align: 'center',
          sortable: true,
          value: 'startTime',
          width: '100px',
        },
        {
          text: 'End Time',
          align: 'center',
          sortable: true,
          value: 'endTime',
          width: '100px',
        },
        {
          text: 'Duration',
          align: 'center',
          sortable: true,
          value: 'duration',
          width: '100px',
        },
        {
          text: 'Rapport sendt',
          align: 'center',
          sortable: false,
          value: 'report.created',
          width: '100px',
        },
        {
          text: 'Location',
          align: 'center',
          sortable: false,
          value: 'location',
          width: '100px',
        },
        {
          text: 'Kunde trukket',
          align: 'center',
          sortable: true,
          value: 'subtracted',
          width: '100px',
        },
        {
          text: 'Overført til PL',
          align: 'center',
          sortable: true,
          value: 'transferred',
          width: '100px',
        },
        {
          text: 'paymentID',
          align: 'center',
          sortable: false,
          value: 'paymentID',
          width: '100px',
        },
      ],
      lessons: [],
      loading: false,
    };
  },
  created() {
    this.search();
  },
  methods: {
    async search() {
      this.loading = true;

      const lessonRequest = await this.$axios.get('/admin/lessons/' + (this.path ? this.path : (this.searchQuery ? this.searchQuery : 'list')));

      this.lessons = lessonRequest.data;
      this.loading = false;

    },
    openInFirestore(route) {
      window.open('https://console.firebase.google.com/u/0/project/learnlink-prod/database/firestore/data/' + route, '_blank').focus();
    },
    simulate(uid) {
      window.open('https://app.learnlink.no/#/simulate/' + uid, '_blank').focus();
    },
    async subtract(lesson) {
      const index = this.lessons.indexOf(lesson);

      lesson.loading = true;
      this.$set(this.lessons, index, lesson);

      await this.$axios
        .post('/balances/subtractFromLesson/' + lesson.ID, {
          employeeInitials: this.$store.state.profile.firstName.charAt(0) + this.$store.state.profile.lastName.charAt(0),
          employeeUID: this.$store.state.profile.uid,
        });

      const updatedLesson = await this.$axios.get('/lessons/' + lesson.ID);

      Object.assign(this.lessons[index], updatedLesson.data);

      lesson = this.lessons[index];
      lesson.loading = false;
      this.$set(this.lessons, index, lesson);
    },
    async transfer(lesson) {
      const index = this.lessons.indexOf(lesson);

      lesson.loading = true;
      this.$set(this.lessons, index, lesson);

      await this.$axios.post('/stripe/transfers/' + lesson.ID);

      const updatedLesson = await this.$axios.get('/lessons/' + lesson.ID);

      Object.assign(this.lessons[index], updatedLesson.data);

      lesson = this.lessons[index];
      lesson.loading = false;
      this.$set(this.lessons, index, lesson);
    },
    async updateAmount(lesson) {
      const projectRes = await this.$axios.get('/projects/' + lesson.projectID);
      const project = projectRes.data;

      let amount = Math.round(((20000 + (project.hourlyLessonCountBonus || 0) + (project.hourlySellerBonus || 0)) / 60) * lesson.duration);

      if (lesson.location === 'home') amount += 20000;
      await this.update(lesson, 'amount', amount);
    },
    async update(lesson, field, value) {
      const index = this.lessons.indexOf(lesson);

      lesson.loading = true;
      this.$set(this.lessons, index, lesson);

      const updateObject = {};
      updateObject[field] = value;
      updateObject.ID = lesson.ID;

      const lessonRes = await this.$axios.put('/lessons/' + lesson.ID, updateObject);
      Object.assign(this.lessons[index], lessonRes.data);

      lesson = this.lessons[index];
      lesson.loading = false;
      this.$set(this.lessons, index, lesson);
    },
    async updateCancellation(lesson, type) {
      const index = this.lessons.indexOf(lesson);

      lesson.loading = true;
      this.$set(this.lessons, index, lesson);

      const lessonRes = await this.$axios.put('/lessons/' + lesson.ID + '/' + type, { ID: lesson.ID });
      Object.assign(this.lessons[index], lessonRes.data);

      lesson = this.lessons[index];
      lesson.loading = false;
      this.$set(this.lessons, index, lesson);
    },
    async updateTravelDuration(lesson) {
      if (lesson.location === 'home') lesson.travelDuration = 30;

      await this.update(lesson, 'travelDuration', lesson.travelDuration);

    },
  },
};
</script>
