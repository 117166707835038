var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      _c(
        "v-card",
        [
          _vm.path === undefined
            ? _c(
                "v-toolbar",
                { attrs: { dark: "", color: "#009bff" } },
                [
                  _c("v-spacer"),
                  _c("v-text-field", {
                    attrs: {
                      "append-icon": "search",
                      label:
                        "Søk etter fornavn, etternavn, epost eller UID (kun ett søkeord)",
                      "single-line": "",
                      "hide-details": ""
                    },
                    on: {
                      keydown: function($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        ) {
                          return null
                        }
                        return _vm.getProjects($event)
                      }
                    },
                    model: {
                      value: _vm.searchQuery,
                      callback: function($$v) {
                        _vm.searchQuery = $$v
                      },
                      expression: "searchQuery"
                    }
                  }),
                  _c(
                    "v-btn",
                    {
                      attrs: { loading: _vm.loading, outlined: "" },
                      on: { click: _vm.getProjects }
                    },
                    [_vm._v("\n        Søk\n      ")]
                  ),
                  _c("v-spacer")
                ],
                1
              )
            : _vm._e(),
          _c(
            "v-toolbar",
            { attrs: { flat: "" } },
            [
              _c("v-spacer"),
              _c("v-text-field", {
                attrs: {
                  label: "Filtrér radene i listen",
                  "single-line": "",
                  "hide-details": ""
                },
                on: {
                  keydown: function($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    ) {
                      return null
                    }
                    return _vm.getProjects($event)
                  }
                },
                model: {
                  value: _vm.filter,
                  callback: function($$v) {
                    _vm.filter = $$v
                  },
                  expression: "filter"
                }
              }),
              _c("v-spacer")
            ],
            1
          ),
          _c("v-data-table", {
            attrs: {
              headers: _vm.headers,
              items: _vm.projects,
              loading: _vm.loading,
              search: _vm.filter,
              "loading-text": "Laster inn oppdrag...",
              "multi-sort": "",
              "no-data-text": "Ingen oppdrag funnet."
            },
            scopedSlots: _vm._u([
              {
                key: "item.ID",
                fn: function(ref) {
                  var item = ref.item
                  return [
                    _c("span", [_vm._v(_vm._s(item.ID))]),
                    _c("br"),
                    _c(
                      "v-btn",
                      {
                        attrs: {
                          color: "blue",
                          dark: "",
                          disabled: _vm.loading,
                          rounded: ""
                        },
                        on: {
                          click: function($event) {
                            return _vm.openDialog("projectDialog", item)
                          }
                        }
                      },
                      [_vm._v("\n          Endre oppdrag\n        ")]
                    ),
                    _c("br"),
                    _c(
                      "v-btn",
                      {
                        attrs: {
                          color: "blue",
                          dark: "",
                          disabled: _vm.loading,
                          rounded: ""
                        },
                        on: {
                          click: function($event) {
                            return _vm.openDialog("notificationDialog", item)
                          }
                        }
                      },
                      [_vm._v("\n          Send meldinger\n        ")]
                    ),
                    _c("br"),
                    _c(
                      "v-btn",
                      {
                        attrs: {
                          color: "blue",
                          dark: "",
                          disabled: _vm.loading,
                          rounded: ""
                        },
                        on: {
                          click: function($event) {
                            return _vm.openDialog("customerDialog", item)
                          }
                        }
                      },
                      [_vm._v("\n          Kontaktinfo\n        ")]
                    )
                  ]
                }
              },
              {
                key: "item.created",
                fn: function(ref) {
                  var item = ref.item
                  return [
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm.$moment.unix(item.created).format("LLLL")) +
                        "\n      "
                    )
                  ]
                }
              },
              {
                key: "item.customer",
                fn: function(ref) {
                  var item = ref.item
                  return [
                    item.customer &&
                    item.customerUID &&
                    item.customerUID.length > 0
                      ? _c("span", [
                          _c(
                            "p",
                            {
                              staticStyle: {
                                "font-size": "18px",
                                "margin-bottom": "0"
                              }
                            },
                            [
                              _c("strong", [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(
                                      item.customerUID.slice(0, 3).toUpperCase()
                                    ) +
                                    "\n            "
                                )
                              ])
                            ]
                          ),
                          _c("p", { staticStyle: { "font-size": "10px" } }, [
                            _vm._v(
                              "\n            " +
                                _vm._s(item.customerUID) +
                                "\n          "
                            )
                          ]),
                          item.customer.account
                            ? _c("p", [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(item.customer.account.phone) +
                                    "\n          "
                                )
                              ])
                            : _vm._e()
                        ])
                      : _vm._e()
                  ]
                }
              },
              {
                key: "item.customer.user.numberOfTimesContactedToday",
                fn: function(ref) {
                  var item = ref.item
                  return [
                    _c("p", { staticStyle: { "margin-bottom": "5px" } }, [
                      _vm._v(
                        "\n          " +
                          _vm._s(
                            item.customer.user.numberOfTimesContactedToday === 1
                              ? item.customer.user.numberOfTimesContactedToday +
                                  " gang"
                              : item.customer.user.numberOfTimesContactedToday +
                                  " ganger"
                          ) +
                          " i dag\n        "
                      )
                    ]),
                    item.customerUID
                      ? _c("contacted-customer", {
                          attrs: {
                            "customer-u-i-d": item.customerUID,
                            "prop-number-of-times-contacted-today":
                              item.customer.user.numberOfTimesContactedToday
                          },
                          on: {
                            contacted: function($event) {
                              return _vm.updateNumberOfTimesContacted(
                                item.customerUID
                              )
                            }
                          }
                        })
                      : _vm._e()
                  ]
                }
              },
              {
                key: "item.seller",
                fn: function(ref) {
                  var item = ref.item
                  return [
                    item.seller && item.sellerUID && item.sellerUID.length > 0
                      ? _c("span", [
                          item.seller.profile
                            ? _c("p", [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(item.seller.profile.firstName) +
                                    " " +
                                    _vm._s(item.seller.profile.lastName) +
                                    "\n          "
                                )
                              ])
                            : _vm._e(),
                          _c("p", { staticStyle: { "font-size": "10px" } }, [
                            _vm._v(
                              "\n            " +
                                _vm._s(item.sellerUID) +
                                "\n          "
                            )
                          ]),
                          item.seller.account
                            ? _c("p", [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(item.seller.account.phone) +
                                    "\n          "
                                )
                              ])
                            : _vm._e()
                        ])
                      : _vm._e(),
                    item.sellerUID && item.sellerUID.length > 0
                      ? _c(
                          "v-btn",
                          {
                            attrs: { color: "#009BFF", dark: "", small: "" },
                            on: {
                              click: function($event) {
                                return _vm.simulate(item.sellerUID)
                              }
                            }
                          },
                          [_vm._v("\n          Simulér\n        ")]
                        )
                      : _vm._e(),
                    item.sellerUID
                      ? _c("syncers", { attrs: { uid: item.sellerUID } })
                      : _vm._e()
                  ]
                }
              },
              {
                key: "item.status",
                fn: function(ref) {
                  var item = ref.item
                  return [_c("span", [_vm._v(_vm._s(item.status))])]
                }
              },
              {
                key: "item.statusUpdated",
                fn: function(ref) {
                  var item = ref.item
                  return [
                    item.statusUpdated > 0
                      ? _c("span", [
                          _vm._v(
                            _vm._s(
                              _vm.$moment
                                .unix(item.statusUpdated)
                                .format("LLLL")
                            )
                          )
                        ])
                      : _c("span", [_vm._v("Ikke tilgjengelig")])
                  ]
                }
              },
              {
                key: "item.readableCategories",
                fn: function(ref) {
                  var item = ref.item
                  return [_c("span", [_vm._v(_vm._s(item.readableCategories))])]
                }
              },
              {
                key: "item.firstLesson",
                fn: function(ref) {
                  var item = ref.item
                  return [
                    item.firstLesson
                      ? _c("span", [
                          _vm._v(
                            _vm._s(
                              _vm.$moment
                                .unix(item.firstLesson.startTime)
                                .format("LLLL")
                            )
                          )
                        ])
                      : _vm._e()
                  ]
                }
              },
              {
                key: "item.lastLesson",
                fn: function(ref) {
                  var item = ref.item
                  return [
                    item.lastLesson
                      ? _c("span", [
                          _vm._v(
                            _vm._s(
                              _vm.$moment
                                .unix(item.lastLesson.startTime)
                                .format("LLLL")
                            )
                          )
                        ])
                      : _vm._e()
                  ]
                }
              },
              {
                key: "item.minutesSold",
                fn: function(ref) {
                  var item = ref.item
                  return [
                    _vm._v(
                      "\n        " +
                        _vm._s(Math.round(item.minutesSold / 60)) +
                        "\n      "
                    )
                  ]
                }
              },
              {
                key: "item.hourlyLessonCountBonus",
                fn: function(ref) {
                  var item = ref.item
                  return [
                    _vm._v(
                      "\n        " +
                        _vm._s(item.hourlyLessonCountBonus / 100) +
                        "\n      "
                    )
                  ]
                }
              },
              {
                key: "item.hourlySellerBonus",
                fn: function(ref) {
                  var item = ref.item
                  return [
                    _vm._v(
                      "\n        " +
                        _vm._s(item.hourlySellerBonus / 100) +
                        "\n      "
                    )
                  ]
                }
              }
            ])
          }),
          _vm.selectedProject && _vm.projectDialogVisible
            ? _c(
                "v-dialog",
                {
                  model: {
                    value: _vm.projectDialogVisible,
                    callback: function($$v) {
                      _vm.projectDialogVisible = $$v
                    },
                    expression: "projectDialogVisible"
                  }
                },
                [
                  _c("project-dialog", {
                    attrs: { project: _vm.selectedProject },
                    on: {
                      update: function($event) {
                        return _vm.updateProject.apply(void 0, arguments)
                      }
                    }
                  })
                ],
                1
              )
            : _vm._e(),
          _vm.selectedProject && _vm.notificationsDialogVisible
            ? _c(
                "v-dialog",
                {
                  model: {
                    value: _vm.notificationsDialogVisible,
                    callback: function($$v) {
                      _vm.notificationsDialogVisible = $$v
                    },
                    expression: "notificationsDialogVisible"
                  }
                },
                [
                  _c("notifications-dialog", {
                    attrs: { project: _vm.selectedProject },
                    on: {
                      contacted: function($event) {
                        return _vm.updateNumberOfTimesContacted(
                          _vm.selectedProject.customerUID
                        )
                      }
                    }
                  })
                ],
                1
              )
            : _vm._e(),
          _vm.selectedProject && _vm.customerDialogVisible
            ? _c(
                "v-dialog",
                {
                  model: {
                    value: _vm.customerDialogVisible,
                    callback: function($$v) {
                      _vm.customerDialogVisible = $$v
                    },
                    expression: "customerDialogVisible"
                  }
                },
                [
                  _c("customer-seller-dialog", {
                    attrs: { project: _vm.selectedProject }
                  })
                ],
                1
              )
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }