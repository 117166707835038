import Vue    from 'vue'
import Router from 'vue-router'
import Home   from './views/Home.vue'

import { auth } from './configuration'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'home',
      component: Home
    },
    {
      path: '/customers',
      name: 'customers',
      component: () => import(/* webpackChunkName: "customers" */ './views/Customers.vue'),
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/sellers',
      name: 'sellers',
      component: () => import(/* webpackChunkName: "sellers" */ './views/Sellers.vue'),
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/lessons',
      name: 'lessons',
      component: () => import(/* webpackChunkName: "lessons" */ './views/Lessons.vue'),
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/projects',
      name: 'projects',
      component: () => import(/* webpackChunkName: "projects" */ './views/Projects.vue'),
      meta: {
        requiresAuth: true
      }
    }
  ]
})

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth) && !auth.currentUser) {
    next('/')
  }
  else {
    next()
  }
})

export default router
