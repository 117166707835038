<template>
  <v-select v-model="picked" :items="alternatives" :label="label" @change="updateField()" />
</template>

<script>
export default {
  props: ['collection', 'doc', 'id', 'field', 'alternatives', 'label'],
  data() {
    return {
      picked: '',
    };
  },
  mounted() {
    // Initialize picker with existing value if it has one.
    this.picked = this.doc[this.field] || '';
  },
  methods: {
    async updateField() {
      const updateObject = {};
      updateObject[this.field] = this.picked;
      updateObject.ID = this.id;
      updateObject.uid = this.id;

      await this.$axios.put('/' + this.collection + '/' + this.id, updateObject, {
        type: 'application/json',
      });

      this.$store.dispatch('displayAlert', {
        color: 'green',
        message: 'Oppdatert',
      });

      // Emitting the entire updated document to make the status-color green.
      this.$emit('success', Object.assign(this.doc, updateObject));
    },
  },
};
</script>
