<template>
  <v-text-field
    v-model="value"
    :label="label"
    :disabled="disabled"
    :hint="hint"
    :placeholder="placeholder"
    @change="update()"
  />
</template>

<script>
export default {
  props: ['collection', 'doc', 'id', 'field', 'label', 'disabled', 'placeholder'],
  data() {
    return {
      value: '',
      hint: '',
      updateObject: {},
    };
  },
  mounted() {
    // Initialize picker with existing value if it has one.
    this.value = this.doc[this.field] || '';
  },
  methods: {
    async update() {
      this.updateObject = {};

      if (this.field === 'email') {
        if (this.validateEmail()) {
          this.hint = '';
          await this.$axios.put('/auth/update/' + this.id, { email: this.value });
          await this.updateField();

          this.$store.dispatch('displayAlert', {
            color: 'green',
            message: 'Oppdatert',
          });

          // Emitting the entire updated document to make the status-color green.
          this.$emit('success', Object.assign(this.doc, this.updateObject));
        }
        else {
          this.hint = 'ugyldig e-postadresse';
        }
      }
      else {
        await this.updateField();

        this.$store.dispatch('displayAlert', {
          color: 'green',
          message: 'Oppdatert',
        });

        // Emitting the entire updated document to make the status-color green.
        this.$emit('success', Object.assign(this.doc, this.updateObject));
      }
    },
    updateField() {
      this.updateObject[this.field] = this.value;
      this.updateObject.ID = this.id;
      this.updateObject.uid = this.id;

      return this.$axios.put('/' + this.collection + '/' + this.id, this.updateObject);
    },
    validateEmail() {
      const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return pattern.test(this.value);
    },
  },
};
</script>
