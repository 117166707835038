var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-tabs",
    { attrs: { color: "primary", "slider-color": "orange", grow: "" } },
    [
      _c("v-tab", [_vm._v("\n    Åpne oppdrag (trenger lærer)\n  ")]),
      _c(
        "v-tab-item",
        [
          _c(
            "v-tabs",
            {
              key: "openProjects",
              attrs: {
                color: "rgb(0, 51, 84)",
                "slider-color": "orange",
                grow: ""
              }
            },
            [
              _c("v-tab", [_vm._v("\n        Landingsside-leads\n      ")]),
              _c(
                "v-tab-item",
                [
                  _c("project-list", {
                    key: "regular-leads",
                    attrs: { path: "regular-leads" }
                  })
                ],
                1
              ),
              _c("v-tab", [_vm._v("\n        Facebook-leads\n      ")]),
              _c(
                "v-tab-item",
                [
                  _c(
                    "v-lazy",
                    [
                      _c("project-list", {
                        key: "facebook-leads",
                        attrs: { path: "facebook-leads" }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c("v-tab", [_vm._v("\n        Godkjent, venter lærer\n      ")]),
              _c(
                "v-tab-item",
                [
                  _c(
                    "v-lazy",
                    [
                      _c("project-list", {
                        key: "approved",
                        attrs: { path: "approved" }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c("v-tab", [
        _vm._v("\n    Lukkede oppdrag (venter kartleggingstime)\n  ")
      ]),
      _c(
        "v-tab-item",
        [
          _c(
            "v-container",
            { attrs: { fluid: "" } },
            [
              _c(
                "v-lazy",
                [
                  _c("project-list", {
                    key: "closed",
                    attrs: { path: "closed" }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c("v-tab", [_vm._v("\n    Status\n  ")]),
      _c(
        "v-tab-item",
        [
          _c(
            "v-tabs",
            {
              attrs: {
                color: "rgb(0, 51, 84)",
                "slider-color": "orange",
                grow: ""
              }
            },
            [
              _c("v-tab", [
                _vm._v("\n        Ønsker ikke å fortsette\n      ")
              ]),
              _c(
                "v-tab-item",
                [
                  _c(
                    "v-lazy",
                    [
                      _c("project-list", {
                        key: "quitting",
                        attrs: { path: "status/quitting" }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c("v-tab", [
                _vm._v("\n        Pause fra undervisningen\n      ")
              ]),
              _c(
                "v-tab-item",
                [
                  _c(
                    "v-lazy",
                    [
                      _c("project-list", {
                        key: "paused",
                        attrs: { path: "status/paused" }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c("v-tab", [_vm._v("\n        Andre statuser\n      ")]),
              _c(
                "v-tab-item",
                [
                  _c(
                    "v-lazy",
                    [
                      _c("project-list", {
                        key: "other",
                        attrs: { path: "status/other" }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c("v-tab", [_vm._v("\n        Får ikke tak i eleven\n      ")]),
              _c(
                "v-tab-item",
                [
                  _c(
                    "v-lazy",
                    [
                      _c("project-list", {
                        key: "notAnswering",
                        attrs: { path: "status/notAnswering" }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c("v-tab", [
                _vm._v("\n        Venter kartleggingstime\n      ")
              ]),
              _c(
                "v-tab-item",
                [
                  _c(
                    "v-lazy",
                    [
                      _c("project-list", {
                        key: "awaitingFirstLesson",
                        attrs: { path: "status/awaitingFirstLesson" }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c("v-tab", [
                _vm._v(
                  "\n        Har hatt kartleggingstime, men ikke avtalt mer\n      "
                )
              ]),
              _c(
                "v-tab-item",
                [
                  _c(
                    "v-lazy",
                    [
                      _c("project-list", {
                        key: "awaiting",
                        attrs: { path: "status/awaiting" }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c("v-tab", [
                _vm._v("\n        I fare for dårlig resultat\n      ")
              ]),
              _c(
                "v-tab-item",
                [
                  _c(
                    "v-lazy",
                    [
                      _c("project-list", {
                        key: "badResultPossible",
                        attrs: { path: "status/badResultPossible" }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c("v-tab", [_vm._v("\n        Alt i orden\n      ")]),
              _c(
                "v-tab-item",
                [
                  _c(
                    "v-lazy",
                    [
                      _c("project-list", {
                        key: "good",
                        attrs: { path: "status/good" }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c("v-tab", [_vm._v("\n    Aktive oppdrag\n  ")]),
      _c(
        "v-tab-item",
        [
          _c(
            "v-container",
            { attrs: { fluid: "" } },
            [
              _c(
                "v-lazy",
                [
                  _c("project-list", {
                    key: "allActive",
                    attrs: { path: "active" }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c("v-tab", [_vm._v("\n    Søk\n  ")]),
      _c(
        "v-tab-item",
        [
          _c(
            "v-container",
            { attrs: { fluid: "" } },
            [_c("v-lazy", [_c("project-list", { key: "search" })], 1)],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }