<template>
  <v-container fluid>
    <v-card>
      <v-toolbar flat dark color="#009bff">
        <v-text-field
          v-model="searchQuery"
          append-icon="search"
          label="Søk etter fornavn, etternavn, epost eller UID (kun ett søkeord)"
          single-line
          hide-details
          :disabled="path !== undefined"
          :placeholder="path ? path : ''"
          @keydown.enter="search"
        />
        <v-spacer />
        <v-btn
          :loading="loading"
          outlined
          @click="search"
        >
          Søk
        </v-btn>
      </v-toolbar>

      <v-data-table
        :headers="headers"
        :items="sellers"
        :loading="loading"
        loading-text="Laster inn lærere..."
        no-data-text="Ingen lærere funnet."
      >
        <template v-slot:item.account.uid="{ item }">
          <span>{{ item.account.uid }}</span>
          <br>
          <v-btn
            color="#009BFF"
            small
            outlined
            @click="simulate(item.user.uid)"
          >
            Simulér
          </v-btn>
          <br>
          <v-btn
            color="#009BFF"
            dark
            small
            outlined
            @click="openProfile(item.user.uid)"
          >
            Åpne profil
          </v-btn>
          <br>
          <v-btn
            v-if="!item.queue"
            :loading="item.loading"
            color="#009BFF"
            dark
            small
            @click="addToQueue(item)"
          >
            Legg i kø
          </v-btn>
          <v-btn
            v-else-if="item.queue"
            :loading="item.loading"
            color="orange"
            dark
            small
            @click="removeFromQueue(item)"
          >
            Slett fra kø
          </v-btn>
          <v-btn
            v-if="item.profile.queueRequest"
            color="error"
            small
            dark
            @click="rejectRequest(item)"
          >
            Avslå forespørsel
          </v-btn>
          <v-btn
            color="primary"
            small
            dark
            @click="openInFirestore('profiles/' + item.user.uid)"
          >
            Åpne i Firestore
          </v-btn>
        </template>

        <template v-slot:item.user.registerDate="{ item }">
          {{ $moment.unix(item.user.registerDate).format('Do MMM') }}
        </template>

        <template v-slot:item.profile.profileUpdated="{ item }">
          {{
            item.profile.profileUpdated ? $moment.unix(item.profile.profileUpdated).format('Do MMM') : 'Ikke oppdatert siden 18. mars'
          }}
        </template>

        <template v-slot:item.profile.categoriesReadable="{ item }">
          <span v-for="c in item.profile.categoriesReadable" :key="c">
            {{ c }}
          </span>
        </template>

        <template v-slot:item.profile.commitmentReadableLessons="{ item }">
          {{ item.profile.commitmentReadableLessons }} timer pr. uke
        </template>

        <template v-slot:item.commitmendReadableSemesters="{ item }">
          {{ item.profile.commitmentReadableSemesters }} semestere
        </template>

        <template v-slot:item.profile.numberOfCustomers="{ item }">
          <span v-if="item.profile.numberOfCustomers">
            {{ Math.round(item.profile.minutesSold / 60) }} timer, {{ item.profile.numberOfCustomers }} elever
          </span>
          <span v-else>
            Ingen erfaring.
          </span>
        </template>

        <template v-slot:stripeCustomerAccount>
          <span v-if="item.stripeManagedAccount">
            {{ item.stripeManagedAccount.id }}
          </span>
        </template>
      </v-data-table>
    </v-card>
  </v-container>
</template>

<script>
export default {
  props: ['path', 'list', 'autoload'],
  data() {
    return {
      sellers: [],
      headers: [
        {
          text: 'Registreringsdato',
          sortable: true,
          value: 'user.registerDate',
        },
        {
          text: 'Oppdateringsdato',
          sortable: true,
          value: 'profile.profileUpdated',
        },
        {
          text: 'Learnlink ID',
          sortable: true,
          value: 'account.uid',
        },
        {
          text: 'Fornavn',
          sortable: true,
          value: 'profile.firstName',
        },
        {
          text: 'Etternavn',
          sortable: true,
          value: 'profile.lastName',
        },
        {
          text: 'Telefon',
          sortable: true,
          value: 'account.phone',
        },
        {
          text: 'Epost',
          sortable: true,
          value: 'account.email',
        },
        {
          text: 'Fag',
          sortable: true,
          value: 'profile.categoriesReadable',
        },
        {
          text: 'Timer pr. uke',
          sortable: true,
          value: 'profile.commitmentReadableLessons',
        },
        {
          text: 'Ukedager',
          sortable: true,
          value: 'profile.weekdays',
        },
        {
          text: 'Antall semestere',
          sortable: true,
          value: 'profile.commitmentReadableSemesters',
        },
        {
          text: 'Erfaring',
          sortable: true,
          value: 'profile.minutesSold',
        },
        {
          text: 'Stripe ID',
          sortable: true,
          value: 'stripeManagedAccount.id',
        },
      ],
      loading: false,
      searchQuery: '',
    };
  },
  watch: {
    list(val) {
      this.sellers = val;
    },
  },
  created() {
    this.search();
  },
  methods: {
    async addToQueue(seller) {
      const index = this.sellers.indexOf(seller);

      seller.loading = true;
      this.$set(this.sellers, index, seller);

      await this.$axios.put('/admin/sellers/queue/approveRequest/' + seller.profile.uid);

      Object.assign(this.sellers[index].profile, seller.profile);
      this.sellers[index].queue = true;

      seller = this.sellers[index];
      seller.loading = false;
      this.$set(this.sellers, index, seller);
    },
    async search() {
      this.loading = true;

      if (this.list && this.list.length) {
        this.sellers = this.list;
      }
      else {
        this.sellers = [];

        const sellerRequest = await this.$axios
          .get('/admin/sellers/' + (this.path ? this.path : (this.searchQuery ? this.searchQuery : 'list')));

        if (!Array.isArray(sellerRequest.data)) this.sellers.push(sellerRequest.data);
        else this.sellers = sellerRequest.data;
      }

      this.loading = false;
    },
    openInFirestore(route) {
      window.open('https://console.firebase.google.com/u/0/project/learnlink-prod/database/firestore/data/' + route, '_blank').focus();
    },
    openProfile(uid) {
      window.open('https://app.learnlink.no/#/profil/' + uid, '_blank').focus();
    },
    async rejectRequest(seller) {
      const index = this.sellers.indexOf(seller);
      seller.loading = true;
      await this.$axios.put('/admin/sellers/queue/rejectRequest/' + seller.account.uid);

      this.sellers.splice(index, 1);
      this.$notify({
        title: 'Søknad avslått',
        message: '',
        type: 'success',
      });

      seller.loading = false;
    },
    async removeFromQueue(seller) {
      const index = this.sellers.indexOf(seller);

      seller.loading = true;
      this.$set(this.sellers, index, seller);

      await this.$axios.delete('/sellers/queue/' + seller.account.uid);
      this.sellers[index].queue = false;

      seller = this.sellers[index];
      seller.loading = false;
      this.$set(this.sellers, index, seller);
    },
    simulate(uid) {
      window.open('https://app.learnlink.no/#/simulate/' + uid, '_blank').focus();
    },
  },
};
</script>
