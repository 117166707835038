<template>
  <v-app id="app">
    <v-navigation-drawer
      v-if="$store.state.account.uid"
      color="white"
      width="170px"
      app
      fixed
      permanent
      expand-on-hover
      stateless
    >
      <div style="margin: 5px">
        <img src="/blue-icon-01-small.png" width="45px">
      </div>

      <v-divider />

      <v-list shaped>
        <v-list-item-group v-model="menuItemActive">
          <v-list-item @click="$router.push('/')">
            <v-list-item-icon>
              <v-icon color="#009bff">
                list
              </v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>
                Oppdrag
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item @click="$router.push('/customers')">
            <v-list-item-icon>
              <v-icon color="#009bff">
                supervisor_account
              </v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>
                Kunder
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item @click="$router.push('/sellers')">
            <v-list-item-icon>
              <v-icon color="#009bff">
                people
              </v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>
                Lærere
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item @click="$router.push('/lessons')">
            <v-list-item-icon>
              <v-icon color="#009bff">
                query_builder
              </v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>
                Timer
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item @click="signOut()">
            <v-list-item-icon>
              <v-icon color="#009bff">
                close
              </v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>
                Logg ut
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>

    <v-content>
      <router-view />
    </v-content>

    <!-- Error snackbar -->
    <v-snackbar
      v-model="$store.state.ui.alert.active"
      :color="$store.state.ui.alert.color"
      :timeout="8000"
      multi-line
    >
      <span style="margin: auto; font-size: 16px">
        {{ $store.state.ui.alert.message }}
      </span>
      <v-btn
        color="white"
        dark
        text
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-snackbar>
    <!-- Error snackbar end -->
  </v-app>
</template>

<script>
import * as Integrations from '@sentry/integrations';
import Vue from 'vue';

export default {
  data() {
    return {
      menuItemActive: 0,
    };
  },
  async created() {
    const version = require('../version.json').version;

    console.info('Learnlink Admin version: ' + version); // eslint-disable-line

    if (window.location.hostname !== 'localhost') {
      this.$sentry.init({
        dsn: 'https://f2a7b9deae1f46b8b4196a49ba8b4d86@sentry.io/1446660',
        environment: this.$env.substring(10),
        release: version,
        integrations: [
          new Integrations.Vue({
            Vue,
            attachProps: true,
          }),
        ],
      });
    }

    this.$auth.onAuthStateChanged(async firebaseUser => {
      if (firebaseUser) {
        if (window.location.hostname !== 'localhost') {
          this.$sentry.configureScope(scope => {
            scope.setUser({
              id: firebaseUser.uid
            })
          })
        }
        await Promise.all([
          this.$store.dispatch('mountAccount', firebaseUser.uid),
          this.$store.dispatch('mountProfile', firebaseUser.uid),
          this.$store.dispatch('mountUser', firebaseUser.uid),
        ]);
        if (this.$store.state.user.uid && !this.$store.state.user.employee) {
          this.signOut();
          this.$notify({
            title: 'Feil',
            message: 'Du har ikke tilgang her',
            type: 'error',
          });
        }
      }
    });
  },
  methods: {
    async signOut() {
      await this.$auth.signOut();
      await this.$store.dispatch('emptyState');
      this.$router.push('/');
    },
  },
};
</script>
