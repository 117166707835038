var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      _c(
        "v-card",
        [
          _c(
            "v-toolbar",
            { attrs: { flat: "", dark: "", color: "#009bff" } },
            [
              _c("v-text-field", {
                attrs: {
                  "append-icon": "search",
                  label:
                    "Søk etter fornavn, etternavn, epost eller UID (kun ett søkeord)",
                  "single-line": "",
                  "hide-details": "",
                  disabled: _vm.path !== undefined,
                  placeholder: _vm.path ? _vm.path : ""
                },
                on: {
                  keydown: function($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    ) {
                      return null
                    }
                    return _vm.search($event)
                  }
                },
                model: {
                  value: _vm.searchQuery,
                  callback: function($$v) {
                    _vm.searchQuery = $$v
                  },
                  expression: "searchQuery"
                }
              }),
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  attrs: { loading: _vm.loading, outlined: "" },
                  on: { click: _vm.search }
                },
                [_vm._v("\n        Søk\n      ")]
              )
            ],
            1
          ),
          _c("v-data-table", {
            attrs: {
              headers: _vm.headers,
              items: _vm.lessons,
              loading: _vm.loading,
              "loading-text": "Laster inn timer...",
              "no-data-text": "Ingen timer funnet"
            },
            scopedSlots: _vm._u([
              {
                key: "item.ID",
                fn: function(ref) {
                  var item = ref.item
                  return [
                    _c("p", { staticClass: "helptext" }, [
                      _vm._v("\n          " + _vm._s(item.ID) + "\n        ")
                    ]),
                    !item.cancelled &&
                    !item.subtracted &&
                      !item.transferred &&
                      (!item.paymentID || item.paymentID === "N/A")
                      ? _c(
                          "v-btn",
                          {
                            attrs: {
                              color: "red",
                              dark: "",
                              small: "",
                              loading: item.loading
                            },
                            on: {
                              click: function($event) {
                                return _vm.updateCancellation(item, "cancel")
                              }
                            }
                          },
                          [_vm._v("\n          Kanseller time\n        ")]
                        )
                      : item.cancelled
                      ? _c(
                          "v-btn",
                          {
                            attrs: {
                              color: "orange",
                              dark: "",
                              small: "",
                              loading: item.loading
                            },
                            on: {
                              click: function($event) {
                                return _vm.updateCancellation(item, "uncancel")
                              }
                            }
                          },
                          [_vm._v("\n          Angre kansellering\n        ")]
                        )
                      : _vm._e(),
                    _c(
                      "v-btn",
                      {
                        attrs: { color: "primary", small: "", dark: "" },
                        on: {
                          click: function($event) {
                            return _vm.openInFirestore("lessons/" + item.ID)
                          }
                        }
                      },
                      [_vm._v("\n          Åpne i Firestore\n        ")]
                    )
                  ]
                }
              },
              {
                key: "item.customerUID",
                fn: function(ref) {
                  var item = ref.item
                  return [
                    _c("span", [
                      item.customer && item.customer.profile
                        ? _c("p", [
                            _vm._v(
                              "\n            " +
                                _vm._s(item.customer.profile.firstName) +
                                "\n            " +
                                _vm._s(item.customer.profile.lastName) +
                                "\n          "
                            )
                          ])
                        : _vm._e(),
                      _c("p", { staticStyle: { "font-size": "10px" } }, [
                        _vm._v(
                          "\n            " +
                            _vm._s(item.customerUID) +
                            "\n          "
                        )
                      ]),
                      item.customer && item.customer.account
                        ? _c("p", { staticStyle: { "font-size": "8px" } }, [
                            _vm._v(
                              "\n            " +
                                _vm._s(item.customer.account.phone) +
                                "\n          "
                            )
                          ])
                        : _vm._e()
                    ]),
                    _c(
                      "v-btn",
                      {
                        attrs: { color: "#009BFF", dark: "", small: "" },
                        on: {
                          click: function($event) {
                            return _vm.simulate(item.customerUID)
                          }
                        }
                      },
                      [_vm._v("\n          Simulér\n        ")]
                    )
                  ]
                }
              },
              {
                key: "item.sellerUID",
                fn: function(ref) {
                  var item = ref.item
                  return [
                    item.seller
                      ? _c("span", [
                          _c("p", [
                            _vm._v(
                              _vm._s(item.seller.profile.firstName) +
                                " " +
                                _vm._s(item.seller.profile.lastName)
                            )
                          ]),
                          _c("p", { staticStyle: { "font-size": "10px" } }, [
                            _vm._v(
                              "\n            " +
                                _vm._s(item.sellerUID) +
                                "\n          "
                            )
                          ]),
                          item.seller.account.phone
                            ? _c("p", { staticStyle: { "font-size": "8px" } }, [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(item.seller.account.phone) +
                                    "\n          "
                                )
                              ])
                            : _vm._e()
                        ])
                      : _vm._e(),
                    _c(
                      "v-btn",
                      {
                        attrs: {
                          color: "#009BFF",
                          dark: "",
                          small: "",
                          disabled: !item.sellerUID
                        },
                        on: {
                          click: function($event) {
                            return _vm.simulate(item.sellerUID)
                          }
                        }
                      },
                      [_vm._v("\n          Simulér\n        ")]
                    )
                  ]
                }
              },
              {
                key: "item.startTime",
                fn: function(ref) {
                  var item = ref.item
                  return [
                    _vm._v(
                      "\n        " +
                        _vm._s(
                          _vm.$moment.unix(item.startTime).format("LLLL")
                        ) +
                        "\n      "
                    )
                  ]
                }
              },
              {
                key: "item.endTime",
                fn: function(ref) {
                  var item = ref.item
                  return [
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm.$moment.unix(item.endTime).format("LLLL")) +
                        "\n      "
                    )
                  ]
                }
              },
              {
                key: "item.duration",
                fn: function(ref) {
                  var item = ref.item
                  return [
                    _c("strong", [
                      _vm._v(_vm._s(Math.round(item.duration / 60)) + " timer")
                    ]),
                    _c("br"),
                    _vm._v(
                      "\n        " + _vm._s(item.duration) + " minutter\n      "
                    )
                  ]
                }
              },
              {
                key: "item.report.created",
                fn: function(ref) {
                  var item = ref.item
                  return [
                    !item.reportID
                      ? _c("span", { staticStyle: { color: "red" } }, [
                          _c("strong", [_vm._v("Mangler rapport!")])
                        ])
                      : item.report.created
                      ? _c("span", [
                          _vm._v(
                            "\n          " +
                              _vm._s(
                                _vm.$moment
                                  .unix(item.report.created)
                                  .format("L")
                              )
                          ),
                          _c("br"),
                          _vm._v(
                            "\n          (" +
                              _vm._s(
                                _vm.$moment
                                  .unix(item.report.created)
                                  .diff(_vm.$moment.unix(item.endTime), "days")
                              ) +
                              " dager for sent)\n        "
                          )
                        ])
                      : _vm._e()
                  ]
                }
              },
              {
                key: "item.location",
                fn: function(ref) {
                  var item = ref.item
                  return [
                    _c("strong", [_vm._v(_vm._s(item.location))]),
                    _c("br"),
                    _c("br"),
                    _vm._v("\n        Duration: "),
                    _c("strong", [_vm._v(_vm._s(item.duration))]),
                    _c("br"),
                    _vm._v("\n        Travel Duration: "),
                    _c("strong", [_vm._v(_vm._s(item.travelDuration))]),
                    item.location === "home" &&
                    (item.paymentID === "N/A" || item.paymentID === "") &&
                    !item.subtracted
                      ? _c(
                          "v-btn",
                          {
                            attrs: {
                              dark: "",
                              small: "",
                              color: "primary",
                              loading: item.loading
                            },
                            on: {
                              click: function($event) {
                                return _vm.updateTravelDuration(item)
                              }
                            }
                          },
                          [_vm._v("\n          Oppdater reisetid\n        ")]
                        )
                      : _vm._e()
                  ]
                }
              },
              {
                key: "item.subtracted",
                fn: function(ref) {
                  var item = ref.item
                  return [
                    _c("p", [
                      _c("strong", [
                        _vm._v(
                          _vm._s(item.duration + (item.travelDuration || 0)) +
                            " minutter"
                        )
                      ])
                    ]),
                    item.subtracted > 0
                      ? _c("span", [
                          _vm._v(
                            _vm._s(
                              _vm.$moment.unix(item.subtracted).format("L")
                            )
                          )
                        ])
                      : item.subtracted === -1
                      ? _c("span", [_vm._v("Trukket manuelt")])
                      : _c("span", [_vm._v("Ikke trukket")]),
                    _c("br"),
                    item.duration &&
                    item.subtracted === 0 &&
                    (item.paymentID === "N/A" || item.paymentID === "") &&
                    (item.travelDuration > 0 || item.location !== "home") &&
                    item.startTime < _vm.$moment().unix()
                      ? _c(
                          "v-btn",
                          {
                            attrs: {
                              color: "orange",
                              dark: "",
                              small: "",
                              loading: item.loading
                            },
                            on: {
                              click: function($event) {
                                return _vm.subtract(item)
                              }
                            }
                          },
                          [_vm._v("\n          Trekk kunde nå\n        ")]
                        )
                      : _vm._e()
                  ]
                }
              },
              {
                key: "item.transferred",
                fn: function(ref) {
                  var item = ref.item
                  return [
                    _c("p", [
                      _c("strong", [
                        _vm._v(
                          _vm._s(Math.round(item.amount / 100) || "MANGLER") +
                            " kr."
                        )
                      ])
                    ]),
                    item.transferred > 0
                      ? _c("span", [
                          _vm._v(
                            _vm._s(
                              _vm.$moment.unix(item.transferred).format("L")
                            )
                          )
                        ])
                      : item.transferred === -1
                      ? _c("span", [_vm._v("Overført manuelt")])
                      : _c("span", [_vm._v("Ikke overført")]),
                    _c("br"),
                    item.amount &&
                    item.transferred === 0 &&
                    (item.paymentID === "N/A" || item.paymentID === "") &&
                    item.startTime < _vm.$moment().unix()
                      ? _c(
                          "v-btn",
                          {
                            attrs: {
                              color: "orange",
                              dark: "",
                              small: "",
                              loading: item.loading
                            },
                            on: {
                              click: function($event) {
                                return _vm.transfer(item)
                              }
                            }
                          },
                          [_vm._v("\n          Overfør\n        ")]
                        )
                      : _vm._e(),
                    item.subtracted !== 0 &&
                    item.transferred === 0 &&
                    (item.paymentID === "N/A" || item.paymentID === "")
                      ? _c(
                          "v-btn",
                          {
                            attrs: {
                              dark: "",
                              small: "",
                              color: "primary",
                              loading: item.loading
                            },
                            on: {
                              click: function($event) {
                                return _vm.updateAmount(item)
                              }
                            }
                          },
                          [_vm._v("\n          Oppdater sum\n        ")]
                        )
                      : _vm._e(),
                    item.subtracted !== 0 &&
                    item.transferred === 0 &&
                    (item.paymentID === "N/A" || item.paymentID === "") &&
                    item.startTime < _vm.$moment().unix()
                      ? _c(
                          "v-btn",
                          {
                            attrs: {
                              color: "orange",
                              dark: "",
                              small: "",
                              loading: item.loading
                            },
                            on: {
                              click: function($event) {
                                return _vm.update(item, "transferred", -1)
                              }
                            }
                          },
                          [_vm._v("\n          Overført manuelt\n        ")]
                        )
                      : _vm._e()
                  ]
                }
              }
            ])
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }