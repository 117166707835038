<template>
  <v-container fluid>
    <v-card>
      <v-toolbar v-if="path === undefined" dark color="#009bff">
        <v-spacer />

        <v-text-field
          v-model="searchQuery"
          append-icon="search"
          label="Søk etter fornavn, etternavn, epost eller UID (kun ett søkeord)"
          single-line
          hide-details
          @keydown.enter="getProjects"
        />

        <v-btn
          :loading="loading"
          outlined
          @click="getProjects"
        >
          Søk
        </v-btn>

        <v-spacer />
      </v-toolbar>

      <v-toolbar flat>
        <v-spacer />

        <v-text-field
          v-model="filter"
          label="Filtrér radene i listen"
          single-line
          hide-details
          @keydown.enter="getProjects"
        />

        <v-spacer />
      </v-toolbar>

      <v-data-table
        :headers="headers"
        :items="projects"
        :loading="loading"
        :search="filter"
        loading-text="Laster inn oppdrag..."
        multi-sort
        no-data-text="Ingen oppdrag funnet."
      >
        <template v-slot:item.ID="{ item }">
          <span>{{ item.ID }}</span>
          <br>
          <v-btn
            color="blue"
            dark
            :disabled="loading"
            @click="openDialog('projectDialog', item)"
            rounded
          >
            Endre oppdrag
          </v-btn>
          <br>
          <v-btn
            color="blue"
            dark
            :disabled="loading"
            @click="openDialog('notificationDialog', item)"
            rounded
          >
            Send meldinger
          </v-btn>
          <br>
          <v-btn
            color="blue"
            dark
            :disabled="loading"
            @click="openDialog('customerDialog', item)"
            rounded
          >
            Kontaktinfo
          </v-btn>
        </template>

        <template v-slot:item.created="{ item }">
          {{ $moment.unix(item.created).format('LLLL') }}
        </template>

        <template v-slot:item.customer="{ item }">
          <span v-if="item.customer && item.customerUID && item.customerUID.length > 0">
            <p style="font-size: 18px; margin-bottom: 0">
              <strong>
                {{ item.customerUID.slice(0, 3).toUpperCase() }}
              </strong>
            </p>
            <p style="font-size: 10px">
              {{ item.customerUID }}
            </p>
            <p v-if="item.customer.account">
              {{ item.customer.account.phone }}
            </p>
          </span>
        </template>

        <template v-slot:item.customer.user.numberOfTimesContactedToday="{ item }">
          <p style="margin-bottom: 5px">
            {{
              item.customer.user.numberOfTimesContactedToday === 1 ?
                (item.customer.user.numberOfTimesContactedToday + ' gang') : (item.customer.user.numberOfTimesContactedToday + ' ganger')
            }} i dag
          </p>
          <contacted-customer
            v-if="item.customerUID"
            :customer-u-i-d="item.customerUID"
            :prop-number-of-times-contacted-today="item.customer.user.numberOfTimesContactedToday"
            @contacted="updateNumberOfTimesContacted(item.customerUID)"
          />
        </template>

        <template v-slot:item.seller="{ item }">
          <span v-if="item.seller && item.sellerUID && item.sellerUID.length > 0">
            <p v-if="item.seller.profile">
              {{ item.seller.profile.firstName }} {{ item.seller.profile.lastName }}
            </p>
            <p style="font-size: 10px">
              {{ item.sellerUID }}
            </p>
            <p v-if="item.seller.account">
              {{ item.seller.account.phone }}
            </p>
          </span>
          <v-btn
            v-if="item.sellerUID && item.sellerUID.length > 0"
            color="#009BFF"
            dark
            small
            @click="simulate(item.sellerUID)"
          >
            Simulér
          </v-btn>
          <syncers v-if="item.sellerUID" :uid="item.sellerUID" />
        </template>

        <template v-slot:item.status="{ item }">
          <span>{{ item.status }}</span>
        </template>

        <template v-slot:item.statusUpdated="{ item }">
          <span v-if="item.statusUpdated > 0">{{ $moment.unix(item.statusUpdated).format('LLLL') }}</span>
          <span v-else>Ikke tilgjengelig</span>
        </template>

        <template v-slot:item.readableCategories="{ item }">
          <span>{{ item.readableCategories }}</span>
        </template>

        <template v-slot:item.firstLesson="{ item }">
          <span v-if="item.firstLesson">{{ $moment.unix(item.firstLesson.startTime).format('LLLL') }}</span>
        </template>

        <template v-slot:item.lastLesson="{ item }">
          <span v-if="item.lastLesson">{{ $moment.unix(item.lastLesson.startTime).format('LLLL') }}</span>
        </template>

        <template v-slot:item.minutesSold="{ item }">
          {{ Math.round(item.minutesSold / 60) }}
        </template>

        <template v-slot:item.hourlyLessonCountBonus="{ item }">
          {{ item.hourlyLessonCountBonus / 100 }}
        </template>

        <template v-slot:item.hourlySellerBonus="{ item }">
          {{ item.hourlySellerBonus / 100 }}
        </template>
      </v-data-table>

      <v-dialog v-if="selectedProject && projectDialogVisible" v-model="projectDialogVisible">
        <project-dialog
          :project="selectedProject"
          @update="updateProject(...arguments)"
        />
      </v-dialog>

      <v-dialog v-if="selectedProject && notificationsDialogVisible" v-model="notificationsDialogVisible">
        <notifications-dialog
          :project="selectedProject"
          @contacted="updateNumberOfTimesContacted(selectedProject.customerUID)"
        />
      </v-dialog>

      <v-dialog v-if="selectedProject && customerDialogVisible" v-model="customerDialogVisible">
        <customer-seller-dialog :project="selectedProject" />
      </v-dialog>
    </v-card>
  </v-container>
</template>

<script>
const ProjectDialog = () => import(
  /* webpackChunkName: "ProjectDialog" */ '@/components/ProjectDialog'
  );
const ContactedCustomer = () => import(
  /* webpackChunkName: "ContactedCustomer" */ '@/components/ContactedCustomer'
  );
const NotificationsDialog = () => import(
  /* webpackChunkName: "NotificationsDialog" */ '@/components/NotificationsDialog'
  );
const CustomerSellerDialog = () => import(
  /* webpackChunkName: "CustomerSellerDialog" */ '@/components/CustomerSellerDialog'
  );

export default {
  components: {
    CustomerSellerDialog,
    NotificationsDialog,
    ContactedCustomer,
    ProjectDialog,
  },
  props: ['path'],
  data() {
    return {
      customerDialogVisible: false,
      projectDialogVisible: false,
      notificationsDialogVisible: false,
      filter: '',
      headers: [
        {
          text: 'ID',
          sortable: true,
          value: 'ID',
        },
        {
          text: 'Created',
          sortable: true,
          value: 'created',
        },
        {
          text: 'Customer',
          align: 'center',
          sortable: true,
          value: 'customer',
        },
        {
          text: 'Fornavn',
          align: 'center',
          sortable: true,
          value: 'customer.profile.firstName',
        },
        {
          text: 'Etternavn',
          align: 'center',
          sortable: true,
          value: 'customer.profile.lastName',
        },
        {
          text: 'Kontaktet i dag',
          align: 'center',
          sortable: true,
          value: 'customer.user.numberOfTimesContactedToday',
        },
        {
          text: 'Seller',
          align: 'center',
          sortable: false,
          value: 'seller',
        },
        {
          text: 'Status',
          align: 'center',
          sortable: true,
          value: 'status',
        },
        {
          text: 'Status oppdatert',
          align: 'center',
          sortable: true,
          value: 'statusUpdated',
        },
        {
          text: 'Categories',
          align: 'center',
          sortable: false,
          value: 'readableCategories',
        },
        {
          text: 'Første time',
          align: 'center',
          sortable: true,
          value: 'firstLesson',
        },
        {
          text: 'Siste time',
          align: 'center',
          sortable: true,
          value: 'lastLesson',
        },
        {
          text: 'Timer registrert',
          align: 'center',
          sortable: true,
          value: 'lessonCount',
        },
        {
          text: 'Timer solgt',
          align: 'center',
          sortable: true,
          value: 'minutesSold',
        },
        {
          text: 'Hourly Lesson Count Bonus (Automatic)',
          align: 'center',
          sortable: true,
          value: 'hourlyLessonCountBonus',
        },
        {
          text: 'Hourly Seller Bonus (Manual)',
          align: 'center',
          sortable: true,
          value: 'hourlySellerBonus',
        },
      ],
      loading: false,
      projects: [],
      searchQuery: '',
      selectedProject: undefined,
    };
  },
  watch: {
    pagination: {
      handler(pagination) {
        const numPages = this.projects.length / this.pagination.rowsPerPage;
        if (this.rowsPerPage !== -1 && pagination.page > 1 && pagination.page > numPages) this.nextPage(pagination.page);
      },
      deep: true,
    },
  },
  created() {
    this.getProjects();
  },
  methods: {
    closeDialogs() {
      this.notificationsDialogVisible = false;
      this.customerDialogVisible = false;
      this.projectDialogVisible = false;
    },
    async nextPage() {
      const projectID = this.projects[this.projects.length - 1].ID;
      const limit = this.pagination.rowsPerPage;

      this.loading = true;

      const nextRes = await this.$axios.get(this.query + '/next/' + limit + '/' + projectID);
      const arr = this.projects;
      this.projects = arr.concat(nextRes.data);

      this.loading = false;
    },
    openDialog(dialogType, project) {
      this.selectedProject = project;

      if (dialogType === 'projectDialog') this.projectDialogVisible = true;
      else if (dialogType === 'customerDialog') this.customerDialogVisible = true;
      else this.notificationsDialogVisible = true;
    },
    async getProjects() {
      this.loading = true;
      const projectRequest = await this.$axios.get('/admin/projects/' + (this.path ? this.path : (this.searchQuery ? ('search/' + this.searchQuery) : 'list')));
      this.projects = projectRequest.data;

      this.loading = false;
    },
    updateNumberOfTimesContacted(customerUID) {
      this.projects.filter(p => p.customerUID === customerUID).forEach(p => {
        const index = this.projects.indexOf(p);
        const project = this.projects[index];
        project.customer.user.numberOfTimesContactedToday = project.customer.user.numberOfTimesContactedToday ? project.customer.user.numberOfTimesContactedToday += 1 : 1;
        this.$set(this.projects, index, project);
      });

      this.closeDialogs();
    },
    async updateProject(updateParams) {
      const index = this.projects.indexOf(this.selectedProject);
      Object.assign(this.selectedProject, updateParams);
      this.$set(this.projects, index, this.selectedProject);

      await this.$axios.put('/projects/' + updateParams.ID, updateParams);

      this.$store.dispatch('displayAlert', {
        color: 'green',
        message: 'Oppdatert',
      });
    },
  },
};
</script>
