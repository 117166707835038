export default {
  agora: {
    appId: process.env.VUE_APP_AGORA_APP_ID,
  },
  aws: {
    accessKey: process.env.VUE_APP_AWS_ACCESS_KEY,
    secretKey: process.env.VUE_APP_AWS_SECRET_KEY,
    bucketName: process.env.VUE_APP_AWS_S3_BUCKET_NAME,
  },
  firebase: {
    apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
    authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN,
    databaseURL: process.env.VUE_APP_FIREBASE_DB_URL,
    projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.VUE_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.VUE_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.VUE_APP_FIREBASE_APP_ID,
    measurementId: process.env.VUE_APP_FIREBASE_MEASUREMENT_ID,
  },
  segment: {
    apiKey: process.env.VUE_APP_SEGMENT_KEY,
  },
}
