var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [!_vm.$store.state.account.uid ? _c("login") : _c("projects")],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }