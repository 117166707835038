<template>
  <v-btn x-small color="grey" :loading="loading" @click="syncWithIntercom">
    Synkroniser med Intercom
  </v-btn>
</template>

<script>
export default {
  name: 'SyncWithIntercom',
  props: {
    uid: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      loading: false,
    };
  },
  methods: {
    async syncWithIntercom() {
      try {
        this.loading = true;
        await this.$axios.post('/intercom/updateIntercomStats/' + this.uid);
      }
      catch (e) {
        this.$store.commit('setError', 'En feil skjedde da vi skulle synkronisere med Intercom for bruker: ' + this.uid + '. Snakk med tech gutta <3');
        console.error(e);
      }

      this.loading = false;
    },
  },
};
</script>
