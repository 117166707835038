import Vue from 'vue';
import Vuex from 'vuex';
import { axios } from '../configuration';

Vue.use(Vuex);

const getDefaultState = () => {
  return {
    account: {},
    profile: {},
    ui: {
      alert: {
        active: false,
        color: '',
        message: '',
      },
    },
    user: {},
  };
};

const store = new Vuex.Store({
  state: getDefaultState(),
  mutations: {
    emptyState(state) {
      Object.assign(state, getDefaultState());
    },
    mountAccount(state, account) {
      state.account = account;
    },
    mountProfile(state, profile) {
      state.profile = profile;
    },
    mountUser(state, user) {
      state.user = user;
    },
    displayAlert(state, payload) {
      state.ui.alert = {
        ...state.ui.alert,
        ...payload,
        active: true,
      };
    },
  },
  actions: {
    displayAlert: ({ commit }, payload) => commit('displayAlert', payload),
    emptyState: ({ commit }) => commit('emptyState'),
    async mountAccount({ commit }, uid) {
      const accountRes = await axios.get('/accounts/' + uid);
      commit('mountAccount', accountRes.data);
    },
    async mountProfile({ commit }, uid) {
      const profileRes = await axios.get('/profiles/' + uid);
      commit('mountProfile', profileRes.data);
    },
    async mountUser({ commit }, uid) {
      const userRes = await axios.get('/users/' + uid);
      commit('mountUser', userRes.data);
    },
  },
});

export default store;
