<template>
  <v-btn x-small color="purple" :loading="loading" @click="syncWithStripe" dark>
    Synkroniser med Stripe
  </v-btn>
</template>

<script>
export default {
  name: 'SyncWithStripe',
  props: {
    uid: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      loading: false,
    };
  },
  methods: {
    async syncWithStripe() {
      try {
        this.loading = true;
        await this.$axios.get('/stripe/syncExternalAccounts/' + this.uid);
      }
      catch (e) {
        this.$store.commit('setError', 'En feil skjedde da vi skulle synkronisere med Stripe for bruker: ' + this.uid + '. Snakk med tech gutta <3');
        console.error(e);
      }

      this.loading = false;
    },
  },
};
</script>
