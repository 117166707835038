var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("v-text-field", {
    attrs: {
      label: _vm.label,
      disabled: _vm.disabled,
      hint: _vm.hint,
      placeholder: _vm.placeholder
    },
    on: {
      change: function($event) {
        return _vm.update()
      }
    },
    model: {
      value: _vm.value,
      callback: function($$v) {
        _vm.value = $$v
      },
      expression: "value"
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }