import Vue from 'vue';
import './plugins/axios';
import Moment from 'moment';
import * as _Sentry from '@sentry/browser';
import vuetify from './plugins/vuetify';
import App from './App.vue';
import router from './router';
import store from './store/store';
import './plugins/element.js';

import { API, auth, environment, storage } from './configuration';

import CustomerList from './components/core/CustomerList';
import DocumentFieldPicker from './components/ProjectDialog/DocumentFieldPicker';
import DocumentPropTextFieldEditor from './components/ProjectDialog/DocumentPropTextFieldEditor';
import LessonList from './components/core/LessonList';
import ProjectList from './components/core/ProjectList';
import SellerList from './components/core/SellerList';

Moment.locale('nb');

Vue.config.productionTip = false;

Vue.component('customer-list', CustomerList);
Vue.component('document-field-picker', DocumentFieldPicker);
Vue.component('document-prop-text-field-editor', DocumentPropTextFieldEditor);
Vue.component('lesson-list', LessonList);
Vue.component('project-list', ProjectList);
Vue.component('seller-list', SellerList);

Vue.prototype.$API = API;
Vue.prototype.$auth = auth;
Vue.prototype.$env = environment;
Vue.prototype.$moment = Moment;
Vue.prototype.$sentry = _Sentry;
Vue.prototype.$storage = storage;

Vue.config.errorHandler = (e) => {
  if (window.location.hostname === 'localhost') console.error(e); // eslint-disable-line
  store.commit('displayAlert', {
    color: 'red',
    message: e.message,
  });

  // If it's an error instance we can pass it directly to Sentry
  if (e instanceof Error) {
    _Sentry.withScope(scope => {
      if (e.response) {
        if (e.response.data && e.response.headers) {
          // The maximum limit for the event payload is 200KB.
          if (e.response.headers['content-length'] < 2 * 10 ** 5) {
            scope.setExtra('Extra data', e.response.data);
          }
          else scope.setExtra('Extra data', 'The response data exceeded 200KB, and is therefore not shown here. Sentry\'s maximum limit for the event payload is 200KB');
        }
      }
      else if (e.request) {
        scope.setExtra('Extra data', 'No response received from the API');
      }
      else {
        scope.setExtra('Extra data', 'No extra data');
      }
      _Sentry.captureException(e);
    });
  }
  else {
    _Sentry.captureException(new Error(e));
  }
};

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App),
}).$mount('#app');
