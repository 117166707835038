<template>
  <v-card>
    <v-toolbar dark color="primary">
      <v-toolbar-title>
        Endre saldoen til {{ customer.profile.firstName }} {{ customer.profile.lastName }}
      </v-toolbar-title>
      <v-spacer />
      <v-toolbar-items>
        <v-btn dark text @click="$emit('close')">
          Lukk
        </v-btn>
      </v-toolbar-items>
    </v-toolbar>

    <v-container>
      <v-row>
        <h1>Saldo: {{ balance.balance }} minutter</h1>
      </v-row>

      <v-row>
        <v-col cols="6">
          <v-row>
            <h2>Endre saldo</h2>
          </v-row>

          <v-row>
            <v-col cols="4">
              <v-text-field
                v-model="minutes"
                :prefix="minutes >= 0 ? 'Legg til' : 'Trekk fra'"
                suffix="minutter"
                type="number"
              />
            </v-col>
            <v-col cols="6">
              <v-text-field
                v-model="description"
                label="Beskrivelse"
                type="text"
              />
            </v-col>
            <v-col cols="2">
              <v-btn color="primary" :disabled="!description || minutes === 0" :loading="loading" @click="submit">
                Send
              </v-btn>
            </v-col>
          </v-row>
        </v-col>

        <v-col cols="6">
          <h2>Tidligere transaksjoner</h2>
          <v-data-table
            :headers="headers"
            :items="balance.transactions"
            :loading="loading"
            no-data-text="Ingen"
            sort-by="created"
            sort-desc
          >
            <template v-slot:item.created="{ item }">
              {{ $moment.unix(item.created).format('LLLL') }}
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
export default {
  name: 'BalanceAdjustmentDialog',
  props: ['customer'],
  data() {
    return {
      balance: {},
      description: '',
      headers: [
        {
          text: 'Tidspunkt',
          value: 'created',
        },
        {
          text: 'Beskrivelse',
          value: 'description',
        },
        {
          text: 'Gammel saldo',
          value: 'oldBalance',
        },
        {
          text: 'Justering',
          value: 'minutes',
        },
        {
          text: 'Ny saldo',
          value: 'newBalance',
        },
        {
          text: 'Endret av',
          value: 'employeeInitials',
        },
      ],
      loading: false,
      minutes: 0,
    };
  },
  created() {
    return this.initialize();
  },
  methods: {
    async initialize() {
      this.loading = true;

      this.minutes = 0;
      this.description = '';

      const balanceRes = await this.$axios
        .get('/balances/' + this.customer.user.uid + '?fields=handleMinusBalance,periodEnd,transactions');
      this.balance = balanceRes.data;

      this.loading = false;
    },
    async submit() {
      this.loading = true;

      await this.$axios.post('/balances/' + this.customer.user.uid, {
        minutes: this.minutes,
        description: this.description,
        employeeInitials: this.$store.state.profile.firstName.charAt(0) + this.$store.state.profile.lastName.charAt(0),
        employeeUID: this.$store.state.profile.uid,
      });
      await this.initialize();

      this.loading = false;
    },
  },
};
</script>
