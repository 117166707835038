var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-btn",
    {
      attrs: { "x-small": "", color: "purple", loading: _vm.loading, dark: "" },
      on: { click: _vm.syncWithStripe }
    },
    [_vm._v("\n  Synkroniser med Stripe\n")]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }