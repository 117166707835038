'use strict';

import Vue from 'vue';
import { auth, axios, sentry } from '@/configuration';

axios.interceptors.request.use(
  async config => {
    // Do something before request is sent
    const requestId = Math.random().toString(36).substr(2, 15);
    sentry.configureScope(scope => {
      scope.setTag('request_id', requestId);
    });
    config.headers['x-request-id'] = requestId;

    if (config.url.includes('/admin') ||
      config.url.includes('/accounts') ||
      config.url.includes('/balances') ||
      config.url.includes('/customers') ||
      config.url.includes('/intercom') ||
      config.url.includes('/lessons') ||
      config.url.includes('/notifications') ||
      config.url.includes('/profiles') ||
      config.url.includes('/projects') ||
      config.url.includes('/reports') ||
      config.url.includes('/sellers') ||
      config.url.includes('/stripe') ||
      config.url.includes('/users')) {
      config.baseURL = process.env.VUE_APP_API2;
    }
    else config.baseURL = process.env.VUE_APP_API;

    const token = await auth.currentUser.getIdToken();
    config.headers.Authorization = 'Bearer ' + token;
    return config;
  },
  error => {
    // Do something with request error
    return Promise.reject(error);
  },
);

// Add a response interceptor
axios.interceptors.response.use(
  response => {
    // Do something with response data
    return response;
  },
  error => {
    // Do something with response error
    Vue.config.errorHandler(error);
    return Promise.reject(error);
  },
);

Plugin.install = function(Vue) {
  Vue.axios = axios;
  window.axios = axios;
  Object.defineProperties(Vue.prototype, {
    axios: {
      get() {
        return axios;
      },
    },
    $axios: {
      get() {
        return axios;
      },
    },
  });
};

Vue.use(Plugin);

export default Plugin;
