import _Axios from 'axios';
import credentials from '@/config/credentials';
import * as _Sentry from '@sentry/browser';

// Check URI. Connect to the corresponding configuration.
const config = {
  baseURL: process.env.VUE_APP_API,
  timeout: 60 * 1000, // Timeout
  withCredentials: false, // Check cross-site Access-Control
};

// Init Firebase
window.firebase.initializeApp(credentials.firebase);

export const API = process.env.VUE_APP_API;
export const auth = window.firebase.auth();
export const axios = _Axios.create(config);
export const environment = process.env.VUE_APP_FIREBASE_PROJECT_ID;
export const firebase = window.firebase;
export const providers = window.firebase.auth;
export const sentry = _Sentry;
export const storage = window.firebase.storage();
