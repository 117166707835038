var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      _c(
        "v-card",
        [
          _c(
            "v-toolbar",
            { attrs: { flat: "", dark: "", color: "#009bff" } },
            [
              _c("v-text-field", {
                attrs: {
                  "append-icon": "search",
                  label:
                    "Søk etter fornavn, etternavn, epost eller UID (kun ett søkeord)",
                  "single-line": "",
                  "hide-details": "",
                  disabled: _vm.path !== undefined,
                  placeholder: _vm.path ? _vm.path : ""
                },
                on: {
                  keydown: function($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    ) {
                      return null
                    }
                    return _vm.search($event)
                  }
                },
                model: {
                  value: _vm.searchQuery,
                  callback: function($$v) {
                    _vm.searchQuery = $$v
                  },
                  expression: "searchQuery"
                }
              }),
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  attrs: { loading: _vm.loading, outlined: "" },
                  on: { click: _vm.search }
                },
                [_vm._v("\n        Søk\n      ")]
              )
            ],
            1
          ),
          _c("v-data-table", {
            attrs: {
              headers: _vm.headers,
              items: _vm.sellers,
              loading: _vm.loading,
              "loading-text": "Laster inn lærere...",
              "no-data-text": "Ingen lærere funnet."
            },
            scopedSlots: _vm._u([
              {
                key: "item.account.uid",
                fn: function(ref) {
                  var item = ref.item
                  return [
                    _c("span", [_vm._v(_vm._s(item.account.uid))]),
                    _c("br"),
                    _c(
                      "v-btn",
                      {
                        attrs: { color: "#009BFF", small: "", outlined: "" },
                        on: {
                          click: function($event) {
                            return _vm.simulate(item.user.uid)
                          }
                        }
                      },
                      [_vm._v("\n          Simulér\n        ")]
                    ),
                    _c("br"),
                    _c(
                      "v-btn",
                      {
                        attrs: {
                          color: "#009BFF",
                          dark: "",
                          small: "",
                          outlined: ""
                        },
                        on: {
                          click: function($event) {
                            return _vm.openProfile(item.user.uid)
                          }
                        }
                      },
                      [_vm._v("\n          Åpne profil\n        ")]
                    ),
                    _c("br"),
                    !item.queue
                      ? _c(
                          "v-btn",
                          {
                            attrs: {
                              loading: item.loading,
                              color: "#009BFF",
                              dark: "",
                              small: ""
                            },
                            on: {
                              click: function($event) {
                                return _vm.addToQueue(item)
                              }
                            }
                          },
                          [_vm._v("\n          Legg i kø\n        ")]
                        )
                      : item.queue
                      ? _c(
                          "v-btn",
                          {
                            attrs: {
                              loading: item.loading,
                              color: "orange",
                              dark: "",
                              small: ""
                            },
                            on: {
                              click: function($event) {
                                return _vm.removeFromQueue(item)
                              }
                            }
                          },
                          [_vm._v("\n          Slett fra kø\n        ")]
                        )
                      : _vm._e(),
                    item.profile.queueRequest
                      ? _c(
                          "v-btn",
                          {
                            attrs: { color: "error", small: "", dark: "" },
                            on: {
                              click: function($event) {
                                return _vm.rejectRequest(item)
                              }
                            }
                          },
                          [_vm._v("\n          Avslå forespørsel\n        ")]
                        )
                      : _vm._e(),
                    _c(
                      "v-btn",
                      {
                        attrs: { color: "primary", small: "", dark: "" },
                        on: {
                          click: function($event) {
                            return _vm.openInFirestore(
                              "profiles/" + item.user.uid
                            )
                          }
                        }
                      },
                      [_vm._v("\n          Åpne i Firestore\n        ")]
                    )
                  ]
                }
              },
              {
                key: "item.user.registerDate",
                fn: function(ref) {
                  var item = ref.item
                  return [
                    _vm._v(
                      "\n        " +
                        _vm._s(
                          _vm.$moment
                            .unix(item.user.registerDate)
                            .format("Do MMM")
                        ) +
                        "\n      "
                    )
                  ]
                }
              },
              {
                key: "item.profile.profileUpdated",
                fn: function(ref) {
                  var item = ref.item
                  return [
                    _vm._v(
                      "\n        " +
                        _vm._s(
                          item.profile.profileUpdated
                            ? _vm.$moment
                                .unix(item.profile.profileUpdated)
                                .format("Do MMM")
                            : "Ikke oppdatert siden 18. mars"
                        ) +
                        "\n      "
                    )
                  ]
                }
              },
              {
                key: "item.profile.categoriesReadable",
                fn: function(ref) {
                  var item = ref.item
                  return _vm._l(item.profile.categoriesReadable, function(c) {
                    return _c("span", { key: c }, [
                      _vm._v("\n          " + _vm._s(c) + "\n        ")
                    ])
                  })
                }
              },
              {
                key: "item.profile.commitmentReadableLessons",
                fn: function(ref) {
                  var item = ref.item
                  return [
                    _vm._v(
                      "\n        " +
                        _vm._s(item.profile.commitmentReadableLessons) +
                        " timer pr. uke\n      "
                    )
                  ]
                }
              },
              {
                key: "item.commitmendReadableSemesters",
                fn: function(ref) {
                  var item = ref.item
                  return [
                    _vm._v(
                      "\n        " +
                        _vm._s(item.profile.commitmentReadableSemesters) +
                        " semestere\n      "
                    )
                  ]
                }
              },
              {
                key: "item.profile.numberOfCustomers",
                fn: function(ref) {
                  var item = ref.item
                  return [
                    item.profile.numberOfCustomers
                      ? _c("span", [
                          _vm._v(
                            "\n          " +
                              _vm._s(
                                Math.round(item.profile.minutesSold / 60)
                              ) +
                              " timer, " +
                              _vm._s(item.profile.numberOfCustomers) +
                              " elever\n        "
                          )
                        ])
                      : _c("span", [
                          _vm._v("\n          Ingen erfaring.\n        ")
                        ])
                  ]
                }
              },
              {
                key: "stripeCustomerAccount",
                fn: function() {
                  return [
                    _vm.item.stripeManagedAccount
                      ? _c("span", [
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.item.stripeManagedAccount.id) +
                              "\n        "
                          )
                        ])
                      : _vm._e()
                  ]
                },
                proxy: true
              }
            ])
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }