import Vue     from 'vue'
import Vuetify from 'vuetify/lib'

Vue.use(Vuetify)

export default new Vuetify({
  dark: false,
  themes: {
    light: {
      prime: '#df8421'
    }
  },
  icons: {
    iconfont: 'mdi'
  }
})
