<template>
  <v-container fluid>
    <v-card>
      <v-toolbar flat dark color="#009bff">
        <v-text-field
          v-model="searchQuery"
          append-icon="search"
          label="Søk etter fornavn, etternavn, epost eller UID (kun ett søkeord)"
          single-line
          hide-details
          :disabled="path !== undefined"
          :placeholder="path ? path : ''"
          @keydown.enter="search"
        />
        <v-spacer />
        <v-btn
          :loading="loading"
          outlined
          @click="search"
        >
          Søk
        </v-btn>
      </v-toolbar>

      <v-data-table
        :items="customers"
        :headers="headers"
        :loading="loading"
        no-data-text="Ingen kunder funnet."
        loading-text="Laster inn kunder..."
      >
        <template v-slot:item.account.uid="{ item }">
          <p style="font-size: 18px; margin-bottom: 0">
            <strong>
              {{ item.account.uid.slice(0, 3).toUpperCase() }}
            </strong>
          </p>
          <span>{{ item.account.uid }}</span>
          <br>
          <v-btn color="#009BFF" dark small @click="simulate(item.user.uid)">
            Simulér
          </v-btn>
          <br>
          <v-btn
            color="primary"
            small
            dark
            @click="openInFirestore('users/' + item.account.uid)"
          >
            Åpne i Firestore
          </v-btn>
          <br>
          <v-btn
            color="orange"
            small
            dark
            @click="openBalanceAdjustmentDialog(item)"
          >
            Endre saldo
          </v-btn>
          <syncers :uid="item.user.uid" />
        </template>

        <template v-slot:item.user.registerDate="{ item }">
          {{ $moment.unix(item.user.registerDate).format('LLLL') }}
        </template>

        <template v-slot:item.balance.balance="{ item }">
          <strong>{{ Math.round((item.balance.balance / 60) * 10) / 10 }} timer</strong><br>
          {{ item.balance.balance }} minutter
          <br>
          <v-btn
            v-if="item.balance.balance < 0"
            color="red"
            small
            dark
            :loading="item.loading"
            @click="invoiceNegativeBalance(item)"
          >
            Fakturer minussaldo nå ({{ Math.round((item.endPrice / 100 / 60) * Math.abs(item.balance.balance)) }} kr.)
          </v-btn>
          <v-btn
            v-if="item.balance.balance < 0"
            color="orange"
            small
            dark
            :loading="item.loading"
            @click="transferNegativeBalanceToInvoiceItem(item)"
          >
            Sett minussaldo på neste faktura ({{ Math.round((item.endPrice / 100 / 60) * Math.abs(item.balance.balance))
            }} kr.)
          </v-btn>
        </template>

        <template v-slot:item.estCorrectBalance="{ item }">
          <span v-if="item.estCorrectBalance">
            <strong>{{ Math.round((item.estCorrectBalance / 60) * 10) / 10 }} timer</strong><br>
            {{ item.estCorrectBalance }} minutter
          </span>
        </template>

        <template v-slot:item.endPrice="{ item }">
          <span v-if="item.endPrice">{{ Math.round(item.endPrice / 100) }} kr. /t</span>
        </template>

        <template v-slot:item.stripeCustomerAccount.metadata.overridePrice="{ item }">
          <span v-if="item.stripeCustomerAccount && item.stripeCustomerAccount.metadata.overridePrice">{{
              item.stripeCustomerAccount.metadata.overridePrice }} kr. /t</span>
        </template>

        <template v-slot:item.stripeCustomerAccount.metadata.fallbackPrice="{ item }">
          <span v-if="item.stripeCustomerAccount && item.stripeCustomerAccount.metadata.fallbackPrice">{{
              item.stripeCustomerAccount.metadata.fallbackPrice }} kr. /t</span>
        </template>

        <template v-slot:item.stripeCustomerAccount.subscriptions="{ item }">
          <span v-if="item.stripeCustomerAccount && item.stripeCustomerAccount.subscriptions.total_count > 0">
            {{ item.stripeCustomerAccount.subscriptions.data[0].plan.nickname }}
          </span>
        </template>
      </v-data-table>
    </v-card>

    <v-dialog
      v-model="balanceAdjustmentDialogVisible"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <balance-adjustment-dialog
        v-if="balanceAdjustmentDialogVisible"
        :customer="activeCustomer"
        @close="balanceAdjustmentDialogVisible = false"
      />
    </v-dialog>
  </v-container>
</template>

<script>
import BalanceAdjustmentDialog from '../BalanceAdjustmentDialog.vue';
import Syncers from "@/components/Syncers";

export default {
  components: {
    Syncers,
    BalanceAdjustmentDialog,
  },
  props: ['path'],
  data() {
    return {
      activeCustomer: {},
      balanceAdjustmentDialogVisible: false,
      customers: [],
      expanded: [],
      headers: [
        {
          text: 'UID',
          sortable: true,
          value: 'account.uid',
        },
        {
          text: 'Fornavn',
          sortable: true,
          value: 'profile.firstName',
        },
        {
          text: 'Etternavn',
          sortable: true,
          value: 'profile.lastName',
        },
        {
          text: 'Registreringsdato',
          sortable: true,
          value: 'user.registerDate',
        },
        {
          text: 'Telefon',
          align: 'center',
          sortable: true,
          value: 'account.phone',
          width: '100px',
        },
        {
          text: 'Stripe Customer ID',
          align: 'center',
          sortable: true,
          value: 'stripeCustomerAccount.id',
          width: '100px',
        },
        {
          text: 'Saldo',
          sortable: true,
          value: 'balance.balance',
          width: '100px',
        },
        {
          text: 'endPrice',
          align: 'center',
          sortable: true,
          value: 'endPrice',
          width: '100px',
        },
        {
          text: 'overridePrice',
          align: 'center',
          sortable: true,
          value: 'stripeCustomerAccount.metadata.overridePrice',
          width: '100px',
        },
        {
          text: 'fallbackPrice',
          align: 'center',
          sortable: true,
          value: 'stripeCustomerAccount.metadata.fallbackPrice',
          width: '100px',
        },
        {
          text: 'Medlemskap',
          sortable: false,
          value: 'stripeCustomerAccount.subscriptions',
        },
      ],
      loading: false,
      searchQuery: '',
    };
  },
  created() {
    this.search();
  },
  methods: {
    invoiceNegativeBalance(customer) {
      const index = this.customers.indexOf(customer);

      customer.loading = true;
      this.$set(this.customers, index, customer);

      return this.$axios
        .post('/customers/invoiceNegativeBalance/' + customer.account.uid + '/' + customer.stripeCustomerAccount.id, {
          employeeInitials: this.$store.state.profile.firstName.charAt(0) + this.$store.state.profile.lastName.charAt(0),
          employeeUID: this.$store.state.profile.uid,
        })
        .then(({ data }) => {
          customer.balance = data;

          Object.assign(this.customers[index], customer);

          customer = this.customers[index];
          customer.loading = false;
          this.$set(this.customers, index, customer);
        });
    },
    openBalanceAdjustmentDialog(customer) {
      this.activeCustomer = customer;
      this.balanceAdjustmentDialogVisible = true;
    },
    async search() {
      this.loading = true;

      const customersRequest = await this.$axios
        .get('/admin/customers/' + (this.path ? this.path : (this.searchQuery ? ('search/' + this.searchQuery) : 'list')));

      this.customers = customersRequest.data;

      this.loading = false;
    },
    openInFirestore(route) {
      window.open('https://console.firebase.google.com/u/0/project/learnlink-prod/database/firestore/data/' + route, '_blank').focus();
    },
    simulate(uid) {
      window.open('https://app.learnlink.no/#/simulate/' + uid, '_blank').focus();
    },
    transferNegativeBalanceToInvoiceItem(customer) {
      const index = this.customers.indexOf(customer);

      customer.loading = true;
      this.$set(this.customers, index, customer);

      return this.$axios
        .post('/customers/transferNegativeBalanceToInvoiceItem/' + customer.account.uid, {
          employeeInitials: this.$store.state.profile.firstName.charAt(0) + this.$store.state.profile.lastName.charAt(0),
          employeeUID: this.$store.state.profile.uid,
        })
        .then(({ data }) => {
          customer.balance = data;

          Object.assign(this.customers[index], customer);

          customer = this.customers[index];
          customer.loading = false;
          this.$set(this.customers, index, customer);
        });
    },
  },
};
</script>
