<template>
  <div>
    <login v-if="!$store.state.account.uid" />
    <projects v-else />
  </div>
</template>

<script>
import Login from '../components/Login'
import Projects from '@/views/Projects';

export default {
  components: {
    Projects,
    Login
  }
}
</script>
