var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      _c(
        "v-card",
        [
          _c(
            "v-toolbar",
            { attrs: { flat: "", dark: "", color: "#009bff" } },
            [
              _c("v-text-field", {
                attrs: {
                  "append-icon": "search",
                  label:
                    "Søk etter fornavn, etternavn, epost eller UID (kun ett søkeord)",
                  "single-line": "",
                  "hide-details": "",
                  disabled: _vm.path !== undefined,
                  placeholder: _vm.path ? _vm.path : ""
                },
                on: {
                  keydown: function($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    ) {
                      return null
                    }
                    return _vm.search($event)
                  }
                },
                model: {
                  value: _vm.searchQuery,
                  callback: function($$v) {
                    _vm.searchQuery = $$v
                  },
                  expression: "searchQuery"
                }
              }),
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  attrs: { loading: _vm.loading, outlined: "" },
                  on: { click: _vm.search }
                },
                [_vm._v("\n        Søk\n      ")]
              )
            ],
            1
          ),
          _c("v-data-table", {
            attrs: {
              items: _vm.customers,
              headers: _vm.headers,
              loading: _vm.loading,
              "no-data-text": "Ingen kunder funnet.",
              "loading-text": "Laster inn kunder..."
            },
            scopedSlots: _vm._u([
              {
                key: "item.account.uid",
                fn: function(ref) {
                  var item = ref.item
                  return [
                    _c(
                      "p",
                      {
                        staticStyle: {
                          "font-size": "18px",
                          "margin-bottom": "0"
                        }
                      },
                      [
                        _c("strong", [
                          _vm._v(
                            "\n            " +
                              _vm._s(
                                item.account.uid.slice(0, 3).toUpperCase()
                              ) +
                              "\n          "
                          )
                        ])
                      ]
                    ),
                    _c("span", [_vm._v(_vm._s(item.account.uid))]),
                    _c("br"),
                    _c(
                      "v-btn",
                      {
                        attrs: { color: "#009BFF", dark: "", small: "" },
                        on: {
                          click: function($event) {
                            return _vm.simulate(item.user.uid)
                          }
                        }
                      },
                      [_vm._v("\n          Simulér\n        ")]
                    ),
                    _c("br"),
                    _c(
                      "v-btn",
                      {
                        attrs: { color: "primary", small: "", dark: "" },
                        on: {
                          click: function($event) {
                            return _vm.openInFirestore(
                              "users/" + item.account.uid
                            )
                          }
                        }
                      },
                      [_vm._v("\n          Åpne i Firestore\n        ")]
                    ),
                    _c("br"),
                    _c(
                      "v-btn",
                      {
                        attrs: { color: "orange", small: "", dark: "" },
                        on: {
                          click: function($event) {
                            return _vm.openBalanceAdjustmentDialog(item)
                          }
                        }
                      },
                      [_vm._v("\n          Endre saldo\n        ")]
                    ),
                    _c("syncers", { attrs: { uid: item.user.uid } })
                  ]
                }
              },
              {
                key: "item.user.registerDate",
                fn: function(ref) {
                  var item = ref.item
                  return [
                    _vm._v(
                      "\n        " +
                        _vm._s(
                          _vm.$moment
                            .unix(item.user.registerDate)
                            .format("LLLL")
                        ) +
                        "\n      "
                    )
                  ]
                }
              },
              {
                key: "item.balance.balance",
                fn: function(ref) {
                  var item = ref.item
                  return [
                    _c("strong", [
                      _vm._v(
                        _vm._s(
                          Math.round((item.balance.balance / 60) * 10) / 10
                        ) + " timer"
                      )
                    ]),
                    _c("br"),
                    _vm._v(
                      "\n        " +
                        _vm._s(item.balance.balance) +
                        " minutter\n        "
                    ),
                    _c("br"),
                    item.balance.balance < 0
                      ? _c(
                          "v-btn",
                          {
                            attrs: {
                              color: "red",
                              small: "",
                              dark: "",
                              loading: item.loading
                            },
                            on: {
                              click: function($event) {
                                return _vm.invoiceNegativeBalance(item)
                              }
                            }
                          },
                          [
                            _vm._v(
                              "\n          Fakturer minussaldo nå (" +
                                _vm._s(
                                  Math.round(
                                    (item.endPrice / 100 / 60) *
                                      Math.abs(item.balance.balance)
                                  )
                                ) +
                                " kr.)\n        "
                            )
                          ]
                        )
                      : _vm._e(),
                    item.balance.balance < 0
                      ? _c(
                          "v-btn",
                          {
                            attrs: {
                              color: "orange",
                              small: "",
                              dark: "",
                              loading: item.loading
                            },
                            on: {
                              click: function($event) {
                                return _vm.transferNegativeBalanceToInvoiceItem(
                                  item
                                )
                              }
                            }
                          },
                          [
                            _vm._v(
                              "\n          Sett minussaldo på neste faktura (" +
                                _vm._s(
                                  Math.round(
                                    (item.endPrice / 100 / 60) *
                                      Math.abs(item.balance.balance)
                                  )
                                ) +
                                " kr.)\n        "
                            )
                          ]
                        )
                      : _vm._e()
                  ]
                }
              },
              {
                key: "item.estCorrectBalance",
                fn: function(ref) {
                  var item = ref.item
                  return [
                    item.estCorrectBalance
                      ? _c("span", [
                          _c("strong", [
                            _vm._v(
                              _vm._s(
                                Math.round((item.estCorrectBalance / 60) * 10) /
                                  10
                              ) + " timer"
                            )
                          ]),
                          _c("br"),
                          _vm._v(
                            "\n          " +
                              _vm._s(item.estCorrectBalance) +
                              " minutter\n        "
                          )
                        ])
                      : _vm._e()
                  ]
                }
              },
              {
                key: "item.endPrice",
                fn: function(ref) {
                  var item = ref.item
                  return [
                    item.endPrice
                      ? _c("span", [
                          _vm._v(
                            _vm._s(Math.round(item.endPrice / 100)) + " kr. /t"
                          )
                        ])
                      : _vm._e()
                  ]
                }
              },
              {
                key: "item.stripeCustomerAccount.metadata.overridePrice",
                fn: function(ref) {
                  var item = ref.item
                  return [
                    item.stripeCustomerAccount &&
                    item.stripeCustomerAccount.metadata.overridePrice
                      ? _c("span", [
                          _vm._v(
                            _vm._s(
                              item.stripeCustomerAccount.metadata.overridePrice
                            ) + " kr. /t"
                          )
                        ])
                      : _vm._e()
                  ]
                }
              },
              {
                key: "item.stripeCustomerAccount.metadata.fallbackPrice",
                fn: function(ref) {
                  var item = ref.item
                  return [
                    item.stripeCustomerAccount &&
                    item.stripeCustomerAccount.metadata.fallbackPrice
                      ? _c("span", [
                          _vm._v(
                            _vm._s(
                              item.stripeCustomerAccount.metadata.fallbackPrice
                            ) + " kr. /t"
                          )
                        ])
                      : _vm._e()
                  ]
                }
              },
              {
                key: "item.stripeCustomerAccount.subscriptions",
                fn: function(ref) {
                  var item = ref.item
                  return [
                    item.stripeCustomerAccount &&
                    item.stripeCustomerAccount.subscriptions.total_count > 0
                      ? _c("span", [
                          _vm._v(
                            "\n          " +
                              _vm._s(
                                item.stripeCustomerAccount.subscriptions.data[0]
                                  .plan.nickname
                              ) +
                              "\n        "
                          )
                        ])
                      : _vm._e()
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: {
            fullscreen: "",
            "hide-overlay": "",
            transition: "dialog-bottom-transition"
          },
          model: {
            value: _vm.balanceAdjustmentDialogVisible,
            callback: function($$v) {
              _vm.balanceAdjustmentDialogVisible = $$v
            },
            expression: "balanceAdjustmentDialogVisible"
          }
        },
        [
          _vm.balanceAdjustmentDialogVisible
            ? _c("balance-adjustment-dialog", {
                attrs: { customer: _vm.activeCustomer },
                on: {
                  close: function($event) {
                    _vm.balanceAdjustmentDialogVisible = false
                  }
                }
              })
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }