<template>
  <div>
    <el-row v-if="!resetDialog" class="login-box">
      <el-row type="flex" justify="space-around" style="text-align: center">
        <img src="../../public/learnlink-logo.png" style="margin: auto" alt="Learnlink logo">
      </el-row>

      <el-form>
        <el-row type="flex" justify="center" style="text-align: center; margin-bottom: 20px">
          <h3 style="margin: 0">
            Logg inn
          </h3>
        </el-row>
        <el-row type="flex" justify="center" style="text-align: center; margin-bottom: 40px" :gutter="5">
          <el-col :xs="14" :sm="14" :md="12" :lg="12">
            <el-input v-model="login.email" type="email" placeholder="E-post" @keyup.enter.native="signInUsingEmail" />
            <el-input
              v-model="login.password"
              type="password"
              placeholder="Passord"
              @keyup.enter.native="signInUsingEmail"
            />
          </el-col>
          <el-col :xs="4" :sm="3" :md="2" :lg="2">
            <el-button type="primary" style="height: 82px" @click="signInUsingEmail">
              Logg inn
            </el-button>
          </el-col>
        </el-row>
      </el-form>
    </el-row>
  </div>
</template>

<script>
export default {
  data() {
    return {
      login: {
        email: '',
        password: '',
      },
      resetEmail: '',
      resetDialog: false,
      resetStep: 0,
      errorTitle: '',
      errorMessage: '',
    };
  },
  methods: {
    async signInUsingEmail() {
      try {
        await this.$auth.signInWithEmailAndPassword(this.login.email, this.login.password);
      }
      catch (e) {
        this.handleLoginError(e);
      }
    },
    handleLoginError(error) {
      if (error.code === 'auth/wrong-password') {
        this.$notify({
          title: 'Feil passord',
          message: 'Du kan få nytt passord ved å klikke på "Glemt passord"',
          type: 'error',
        });
      }
      else if (error.code === 'auth/user-not-found') {
        this.$notify({
          title: 'Fant ikke brukeren',
          message: 'Prøv en annen epostadresse. Undervisere logger inn med Facebook.',
          type: 'error',
        });
      }
      else if (error.code === 'auth/network-request-failed') {
        this.$notify({
          title: 'Wops!',
          message: 'Det ser ut til at internett-tilkoblingen din er borte. Det kan hjelpe å starte nettleseren på nytt.',
          type: 'error',
        });
      }
      else {
        this.$notify({
          title: 'Oops',
          message: error.message,
          type: 'error',
        });
      }
    },
  },
};
</script>

<style scoped>
h1,
h2,
h3,
h4,
h5,
h6 {
  color: #FFF;
}

.login-box {
  background: rgba(0, 0, 0, 0.62);
  padding: 25px 20px 30px;
  width: 50vw;
  height: 450px;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  max-width: 500px;
}

@media only screen and (max-width: 800px) {
  .login-box {
    padding: 25px 20px;
    width: 100%;
    height: 50vh;
    min-height: 470px;
  }
}

/* Shared */
.loginBtn {
  box-sizing: border-box;
  position: relative;
  /* width: 13em;  - apply for fixed size */
  margin: 0.2em;
  padding: 0 15px 0 46px;
  border: none;
  text-align: left;
  line-height: 34px;
  white-space: nowrap;
  border-radius: 0.2em;
  font-size: 16px;
  color: #FFF;
  width: 150px;
}

.loginBtn:before {
  content: "";
  box-sizing: border-box;
  position: absolute;
  top: 0;
  left: 0;
  width: 34px;
  height: 100%;
}

.loginBtn:focus {
  outline: none;
}

.loginBtn:active {
  box-shadow: inset 0 0 0 32px rgba(0, 0, 0, 0.1);
}

/* Facebook */
.loginBtn--facebook {
  background-color: #4C69BA;
  background-image: linear-gradient(#4C69BA, #3B55A0);
  /*font-family: "Helvetica neue", Helvetica Neue, Helvetica, Arial, sans-serif;*/
  text-shadow: 0 -1px 0 #354C8C;
}

.loginBtn--facebook:before {
  border-right: #364e92 1px solid;
  background: url('https://s3-us-west-2.amazonaws.com/s.cdpn.io/14082/icon_facebook.png') 6px 6px no-repeat;
}

.loginBtn--facebook:hover,
.loginBtn--facebook:focus {
  background-color: #5B7BD5;
  background-image: linear-gradient(#5B7BD5, #4864B1);
}

/* Google */
.loginBtn--google {
  /*font-family: "Roboto", Roboto, arial, sans-serif;*/
  background: #DD4B39;
}

.loginBtn--google:before {
  border-right: #BB3F30 1px solid;
  background: url('https://s3-us-west-2.amazonaws.com/s.cdpn.io/14082/icon_google.png') 6px 6px no-repeat;
}

.loginBtn--google:hover,
.loginBtn--google:focus {
  background: #E74B37;
}
</style>
