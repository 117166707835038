var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-btn",
    {
      attrs: { "x-small": "", color: "grey", loading: _vm.loading },
      on: { click: _vm.syncWithIntercom }
    },
    [_vm._v("\n  Synkroniser med Intercom\n")]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }